import { Box } from '@material-ui/core';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { styled } from '@mui/material/styles';
import clsx from 'clsx';
import { IconDrag } from 'icons';
import * as React from 'react';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { sortableContainer, sortableElement } from 'react-sortable-hoc';
import { AutoSizer, CellMeasurer, CellMeasurerCache, Column, Table } from 'react-virtualized';
import RowActions from './RowActions/RowActions';
import './tableGlobalStyles.css';

const classes = {
    flexContainer: 'ReactVirtualizedDemo-flexContainer',
    tableRow: 'ReactVirtualizedDemo-tableRow',
    tableRowHover: 'ReactVirtualizedDemo-tableRowHover',
    tableCell: 'ReactVirtualizedDemo-tableCell',
    noClick: 'ReactVirtualizedDemo-noClick',
    columnHeader: 'ReactVirtualized__Table__headerColumn',
};


const styles = ({ theme }) => ({
    // temporary right-to-left patch, waiting for
    // https://github.com/bvaughn/react-virtualized/issues/454
    '& .ReactVirtualized__Table__headerRow': {
        ...(theme.direction === 'rtl' && {
            paddingLeft: '0 !important',
        }),
        ...(theme.direction !== 'rtl' && {
            paddingRight: undefined,
        }),
        borderBottom: '1px solid #0000000d',
    },
    [`& .${classes.flexContainer}`]: {
        display: 'flex',
        alignItems: 'center',
        boxSizing: 'border-box',
    },
    [`& .${classes.tableRow}`]: {
        cursor: 'pointer',
        borderBottom: '1px solid #0000000d',
    },
    [`& .${classes.tableRowHover}`]: {
        '&:hover': {
            backgroundColor: theme.palette.grey[200],
            boxShadow: '19px 9px 20px 6px rgba(0, 0, 0, 0.1)',
        },
    },
    [`& .${classes.tableCell}`]: {
        flex: 1,
        borderBottom: '1px solid #0000000d'
    },
    [`& .${classes.noClick}`]: {
        cursor: 'initial',
    },
    [`& .${classes.columnHeader}`]: {
        cursor: 'pointer',
        color: theme.palette.primary.light,
        backgroundColor: 'white'
    },
});

const MuiVirtualizedTable = ({
    columns,
    onColumnOrderChange,
    allowColumnCustomization,
    customStyling,
    allowHorizontalScroll,
    baselineColumnWidth = 150,
    getRowClassNameFunc,
    showActionsInMenu,
    ...props
}) => {

    const [showContextMenuRowIndex, setShowContextMenuRowIndex] = useState()
    const [contextMenuEvent, setContextMenuEvent] = useState()

    const defaultProps = {
        headerHeight: 54,
        rowHeight: 60,
    };

    const _cache = new CellMeasurerCache({
        fixedHeight: true,
        fixedWidth: false,
        minHeight: 60,
    });

    const _lastRenderedWidth = props.width;
    const _lastRenderedHeight = props.height;

    const getRowClassName = ({ index }) => {
        const { onRowClick } = props;

        const clsxs = clsx(classes.tableRow, classes.flexContainer,
            { [classes.tableRowHover]: index !== -1 && onRowClick != null },
            { [classes.columnHeader]: index == -1 && !props.headerStyle },
            getRowClassNameFunc && getRowClassNameFunc(index)
        );
        return clsxs;
    };

    const cellRenderer = ({ cellData, columnIndex, dataKey, parent, rowIndex }) => {
        const { actions } = props;
        const column = columns[columnIndex];
        let value = cellData;

        if (column.values) {
            let v = column.values.find(v => v.id == value) || {};
            value = v.name;
        }

        if (value && column.icon) {
            value = <Link href={value} target="_blank">{column.icon}</Link>;
        }

        if (column.component && typeof column.component == 'function') {
            let rowObject = rowGetter({ index: rowIndex });
            value = column.component(rowObject);
        }

        if (actions && column.isAction) {
            value = <RowActions showActionsInMenu={showActionsInMenu} actions={actions} cellData={cellData} />
        }

        return (
            <CellMeasurer
                cache={_cache}
                columnIndex={columnIndex}
                key={dataKey}
                parent={parent}
                rowIndex={rowIndex}
            >
                <div
                    onContextMenu={(e) => {
                        e.preventDefault();
                        setContextMenuEvent(e);
                        setShowContextMenuRowIndex(rowIndex);
                    }}
                    style={customStyling ? {} : {
                        fontSize: '0.9em',
                        background: columnIndex % 2 ? 'white' : '#f4f9ff',
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        padding: '10px'
                    }}
                >
                    {value}
                </div>
            </CellMeasurer>
        );
    };

    const headerRenderer = ({ label, columnIndex, dataKey, rowIndex, parent }) => {
        const { columnToSort, sortDirection, onSort, headerStyle } = props;
        const column = columns[columnIndex];

        return (
            <CellMeasurer
                cache={_cache}
                columnIndex={columnIndex}
                key={dataKey}
                parent={parent}
                rowIndex={rowIndex}
            >
                <Box

                    className={!headerStyle && classes.columnHeader}
                    style={customStyling ? { ...headerStyle } : { fontWeight: '500', background: columnIndex % 2 ? 'white' : '#f4f9ff', width: '100%', height: '100%', display: 'flex', alignItems: 'end', padding: '10px', ...headerStyle }}
                    onClick={() => {
                        onSort(column.sortKey || column.key);
                    }}
                >
                    <div style={{ width: '100%' }} >
                        {allowColumnCustomization && column?.customizable !== false && <div style={{ textAlign: 'center' }}><IconDrag /></div>}
                        <div>
                            {column.label}
                        </div>
                    </div>
                    {columnToSort && columnToSort === (column.sortKey || column.key) ? (
                        sortDirection === "asc" ? (
                            <ExpandLessIcon />
                        ) : (
                            <ExpandMoreIcon />
                        )
                    ) : <></>}
                </Box>
            </CellMeasurer>
        );
    };

    const renderHeaderRow = (params) => {
        return (
            <SortableHeaderRowRenderer
                {...params}
                columnConfig={columns}
                allowColumnCustomization={allowColumnCustomization}
                axis="x"
                lockAxis="x"
                onSortEnd={onSortEnd}
                helperClass={'tableColumnHeaderDragging'}
                distance={5}
                shouldCancelStart={(el) => {
                }}
            />
        );
    };

    const onSortEnd = ({ oldIndex, newIndex }) => {
        onColumnOrderChange(oldIndex, newIndex);

    };

    const { sortDirection, columnToSort, rowHeight, headerHeight, headerStyle, width, height, rowCount, setRef, refresh, rowGetter, rowIdentifier, ...tableProps } = props;

    if (_lastRenderedWidth !== width) {
        _lastRenderedWidth = width;
        _cache.clearAll();
    }

    if (_lastRenderedHeight !== height) {
        _lastRenderedHeight = height;
        _cache.clearAll();
    }

    const optimalWidth = columns.length * baselineColumnWidth;
    return (
        <>
            <AutoSizer>
                {({ height, width }) => (
                    <Table
                        height={height - 20}
                        width={allowHorizontalScroll && width < optimalWidth ? optimalWidth : width}
                        gridStyle={{
                            direction: 'inherit',
                        }}
                        headerHeight={headerHeight ?? defaultProps.headerHeight}
                        rowGetter={rowGetter}
                        {...tableProps}
                        rowClassName={getRowClassName}
                        overscanRowCount={2}
                        rowHeight={_cache.rowHeight}
                        rowCount={rowCount}
                        headerRowRenderer={renderHeaderRow}
                        rowStyle={{ alignItems: "stretch" }}
                    >
                        {columns?.map(({ dataKey, ...other }, index) => {
                            return (
                                <Column
                                    key={dataKey}
                                    headerRenderer={(headerProps) =>
                                        headerRenderer({
                                            ...headerProps,
                                            columnIndex: index,
                                        })
                                    }
                                    className={classes.flexContainer}
                                    cellRenderer={cellRenderer}
                                    dataKey={dataKey}
                                    width={other?.width ?? ((width < optimalWidth ? optimalWidth : width) / columns?.length)}
                                    flexGrow={index == columns?.length - 1 ? 1 : undefined}
                                    {...other}
                                />
                            );
                        })}
                    </Table>
                )
                }
            </AutoSizer >
            {showActionsInMenu && <RowActions
                actions={props?.actions}
                isContextMenu
                showActionsInMenu={showActionsInMenu}
                cellData={rowGetter({ index: showContextMenuRowIndex })?.[rowIdentifier]}
                contextMenuEvent={contextMenuEvent}
            />}
        </>
    );
}


const VirtualizedTable = styled(MuiVirtualizedTable)(styles);
export default VirtualizedTable;

const SortableHeaderRowRenderer = sortableContainer(
    ({ columnConfig, allowColumnCustomization, className, columns, style }) => (
        <div className={className} role="row" style={style}>
            {React.Children.map(columns, (column, index) => (
                <>{
                    (columnConfig?.[index]?.customizable === false || !allowColumnCustomization) ? <NonSortableHeader index={index} >{column}</NonSortableHeader> :
                        <SortableHeader index={index} >{column}</SortableHeader>
                }</>

            ))}
        </div>
    ),
);

const SortableHeader = sortableElement(({ children, ...props }) =>
    React.cloneElement(children, props),
);
const NonSortableHeader = ({ children, ...props }) =>
    React.cloneElement(children, props);

