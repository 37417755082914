import { Button, Grid } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import Authorize from 'components/Authorize';
import { permissionProfiles } from 'components/Authorize/permissionProfiles';
import DataListHeader from "components/DataListHeader";
import DataTable from "components/DataTable/DataTable";
import ExportButton from "components/ExportButton/ExportButton";
import LoadingSpinner from 'components/LoadingSpinner/LoadingSpinner';
import NotificationProvider from 'components/NotificationProvider';
import PageComponent from 'components/Page/PageComponent';
import { useGetLotsQuery } from 'features/lots/lotSlice';
import { relationTypeEnumById } from 'features/users/UsersEnums';
import { useGetAllRelationsQuery, useGetUsersQuery } from 'features/users/usersSlice';
import usePrompt from 'hooks/usePrompt';
import useSortOptions from "hooks/useSortOptions";
import { groupBy, orderBy } from "lodash";
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from 'react-router-dom';
import FilterService, { fieldTypes } from "services/filterService";
import FormatService from "services/formatService";
import { meetingPlaceEnum } from './OneOnOneEnums';
import { setFilters } from './OneOnOneFilterSlice';
import OneOnOneForm from './OneOnOneForm';
import { useDeleteOneOnOneMutation, useGetAllWorkersWithDeletedQuery, useGetOneOnOnesQuery } from './OneOnOneSlice';
import { OneOnOneStyle } from "./OneOnOneStyle";
import { arrayToObject } from 'utils/object-util';

const OneOnOnesPage = () => {
    const classes = OneOnOneStyle();
    const dispatch = useDispatch();
    const { triggerPrompt } = usePrompt();

    let { data: oneOnOnes, error: oneOnOnesError, isLoading: isLoadingOneOnOnes } = useGetOneOnOnesQuery();
    let { data: lotWorkers, error: workersError, isLoading: isLoadingWorkers } = useGetAllWorkersWithDeletedQuery();
    let { data: lots, error: lotsError, isLoading: isLoadingLots } = useGetLotsQuery();
    let { data: users, error: usersError, isLoading: isLoadingUsers } = useGetUsersQuery();
    let { data: allRelations, error: allRelationsError, isLoading: isLoadingAllRelations } = useGetAllRelationsQuery();
    users = users?.data || [];
    lots = lots || [];
    lotWorkers = lotWorkers || [];
    oneOnOnes = oneOnOnes || [];
    allRelations = allRelations || [];

    const history = useHistory();

    const { filters } = useSelector(state => state.oneOnOneFilters);
    const [sortOptions, sortData] = useSortOptions();
    const [meetingToEdit, setMeetingToEdit] = useState(null);
    const [deleteOneOnOne, { isLoading: isLoadingDeleteOneOnOne }] = useDeleteOneOnOneMutation();

    const lotWorkersLookup = arrayToObject(lotWorkers);

    let regionalManagers = [... new Set(allRelations?.filter(relation => relation.relationType === relationTypeEnumById.REGIONAL_MANAGER))];
    const groupedToUserId = groupBy(regionalManagers, 'userId');
    const dropdownValues = Object.keys(groupedToUserId).map((key) => ({ id: key, name: groupedToUserId[key]?.[0].userName }));

    let filterConfig = [
        { type: fieldTypes.ASSOCIATED_LOT, size: 3, title: 'Lot', key: 'lotId', values: lots },
        { type: fieldTypes.DATERANGE, size: 3, title: 'Date', key: 'formatedMeetingDate', values: [] },
        { type: fieldTypes.VALUE_LIST, size: 3, title: 'Regional Manager', key: 'regionalManager', values: dropdownValues },
    ];

    const updateFilters = (prop, value) => {
        dispatch(setFilters({ ...filters, [prop]: { value } }));
    }

    const onDropFilter = (filterValue, prop) => {
        updateFilters(prop, filterValue);
    };

    oneOnOnes = oneOnOnes?.map(o => ({
        ...o,
        employeeName: `${lotWorkersLookup?.[o.employeeId]?.firstName} ${lotWorkersLookup?.[o.employeeId]?.lastName} ${lotWorkersLookup?.[o.employeeId]?.deletedDate ? '(Deleted)' : ''}`,
        SupervisorEmail: users?.find(u => u.id == o.supervisorId)?.email,
        lot: lots?.find(l => l.id == o.lotId)?.name,
        meetingPlaceName: meetingPlaceEnum?.find(e => e.id == o.meetingPlace)?.name,
        formatedMeetingDate: FormatService.formatDate(o.meetingDate),
        regionalManager: regionalManagers?.filter(r => r.lotId === o.lotId)?.map(r => r.userId)
    }));

    let oneOnOnesColumns = [
        { name: "Employee Name", key: "employeeName" },
        { name: "Supervisor Name", key: "SupervisorEmail" },
        { name: "Lot", key: "lot" },
        { name: "Meeting Date", key: "formatedMeetingDate" },
        { name: "Meeting Place", key: "meetingPlaceName" },
    ];

    const onEdit = (id) => {
        setMeetingToEdit(oneOnOnes?.find(o => o.id == id));
    };

    const onDelete = async (id) => {
        triggerPrompt({
            title: "Delete One On One",
            content: "Are you sure you want to delete the one on one?",
            loading: isLoadingDeleteOneOnOne,
            onConfirm: async () => {
                let res = await deleteOneOnOne({ id });

                if (!res?.error) {
                    NotificationProvider.success('Deleted successfully');
                } else {
                    NotificationProvider.error('Failed to delete');
                }
            },
        });
    };

    const rowActions = (rowId) => ([
        {
            icon: <EditIcon />,
            onClick: () => onEdit(rowId)
        },
        {
            icon: <DeleteIcon />,
            onClick: () => onDelete(rowId)
        }
    ]);

    const mapExportData = o => ({
        EmployeeName: o.employeeName,
        SupervisorEmail: o.SupervisorEmail,
        Lot: o.lot,
        MeetingDate: o.formatedMeetingDate,
        MeetingPlace: o.meetingPlaceName,
        Opportunities: o.opportunities,
        NextSteps: o.nextSteps
    });

    const isLoading = () => isLoadingOneOnOnes || isLoadingWorkers || isLoadingLots || isLoadingUsers;

    let filteredOneOnOnes = FilterService.filter(filters, null, oneOnOnes, filterConfig);

    useEffect(() => {
        let filterField = history?.location?.state?.params?.filters;
        if (filterField) {
            for (let i in filterField) {
                updateFilters(i, filterField[i]);
            }
        }
    }, [])

    return (
        <Authorize profile={permissionProfiles.DASHBOARD.ONE_ON_ONE}>
            <PageComponent
                header={<DataListHeader
                    title={'One On Ones'}
                    data={oneOnOnes}
                    dropDownsConfig={filterConfig}
                    filters={filters}
                    onDropFilter={onDropFilter}
                    actionButtons={(
                        <Grid container spacing={1}>
                            <Grid item>
                                <Button variant='contained' color='primary' onClick={() => history.push(`/oneonones/new`)} >Add New One On One</Button>
                            </Grid>
                            <Grid item><ExportButton fileLabel="one on one" data={filteredOneOnOnes} mapExportData={mapExportData} /></Grid>
                        </Grid>
                    )}
                />}
            >
                <>
                    {
                        isLoading() ? <LoadingSpinner loading={isLoading()} /> :
                            <div className={classes.table}>
                                <DataTable
                                    columns={oneOnOnesColumns}
                                    rows={orderBy(
                                        filteredOneOnOnes,
                                        sortOptions.columnToSort,
                                        sortOptions.sortDirection
                                    )}
                                    rowIdentifier='id'
                                    onSort={sortData}
                                    sortDirection={sortOptions.sortDirection}
                                    columnToSort={sortOptions.columnToSort}
                                    noItemsMessage='There are no one on ones to display'
                                    actions={rowActions}
                                    maxActionCount={2}
                                />
                            </div>

                    }
                </>
                {window.location.pathname.includes('/new') && <OneOnOneForm meetingToEdit={null} />}
                {meetingToEdit && <OneOnOneForm meetingToEdit={meetingToEdit} />}
            </PageComponent>
        </Authorize>
    );
};

export default OneOnOnesPage;
