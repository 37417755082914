import { Grid, useMediaQuery } from "@material-ui/core";
import EmptyPlaceholder from "components/EmptyPlaceholder/EmptyPlaceholder";
import NotificationProvider from "components/NotificationProvider";
import LoadAttachments from "features/loads/components/LoadAttachments";
import { NoteEntityTypes } from "features/notes/notesConsts";
import FadeInImage from "features/vehicles/components/FadeInImage";
import { useGetImageTypesQuery } from "features/vehicles/vehicleSlice";
import { useState } from "react";
import FormatService from "services/formatService";
import mobileBreakpoint from "utils/mobileBreakpoint";
import { VehicleDocumentsStyle } from "../VehicleDocuments/style";
import { useDeleteVehicleImageMutation, useUpdateImageMutation } from "features/vehicles/vehicleAttachmentsSlice";
import { VehiclePendingSyncCheckIns } from "features/vehicles/VehicleCustomForm/syncProgress/VehiclePendingSyncCheckIns";

export const VehiclePhotos = ({ vehicleDetails, latestLoad }) => {
    const classes = VehicleDocumentsStyle();

    let [deleteImage] = useDeleteVehicleImageMutation();
    let [updateImageType] = useUpdateImageMutation();

    let { data: imageTypes, error: imageTypesError, isLoading: isLoadingImageTypes } = useGetImageTypesQuery();

    let [existingImages, setExistingImages] = useState();
    const isMobile = useMediaQuery(mobileBreakpoint);
    let images = vehicleDetails?.images || [];
    images = images.map(a => ({
        ...a,
        createdDate: FormatService.formatDate(a.createdDate)
    }))
    images = images.sort((a, b) => (new Date(a.createdDate) - new Date(b.createdDate)));

    if (!existingImages) {
        setExistingImages(images);
    }

    const groupedImagesByDate = {};

    images.forEach(img => {
        if (!groupedImagesByDate[img.createdDate]) groupedImagesByDate[img.createdDate] = [];
        groupedImagesByDate[img.createdDate].push(img);
    });

    let imagesDates = Object.keys(groupedImagesByDate);

    const onDeleteConfirm = async (img) => {
        const imageToDelete = { ...img, createdDate: new Date(img.createdDate) }
        const res = await deleteImage(imageToDelete);
        if (res.error) {
            NotificationProvider.error("Failed to delete the image");
        } else {
            NotificationProvider.success("Successfully deleted the image");
        }
    }

    const changeImageTypeId = (image) => {
        setExistingImages([...existingImages].map((img) => {
            if (img.id == image.id) {
                return {
                    id: img.id,
                    url: img.url,
                    createdDate: img.createdDate,
                    imageTypeId: image.imageTypeId,
                    fileName: image.fileName
                };
            }
            else {
                return img;
            }
        }));
    };

    const onEditConfirm = async (img) => {
        let imageToUpdate = existingImages?.find(x => x.id == img.id);
        let res = await updateImageType({
            "id": imageToUpdate.id,
            "vehicleId": vehicleDetails?.id,
            "filename": imageToUpdate.fileName,
            "file_type": "image/png",
            "FileUrl": imageToUpdate.url,
            "ImageTypeId": imageToUpdate.imageTypeId
        });

        if (res.error) {
            NotificationProvider.error("Failed to change the image type");
        } else {
            res = res.data;
            img.url = res.fileUrl;
            img.id = res.id;
            img.imageTypeId = imageToUpdate.imageTypeId;
            img.fileName = imageToUpdate.fileName;
            setExistingImages(images);
            NotificationProvider.success("Successfully changed the image type");
        }
    }

    return (
        <>
            <Grid container>
                {!(!!images?.length) && <EmptyPlaceholder text='No Photos & Documents' />}
                {imagesDates?.map((imageDate) =>
                    <Grid container direction="column">
                        <Grid item>
                            <div>Date: {!!imageDate ? imageDate : 'Unknown'}</div>
                        </Grid>
                        <Grid container>
                            {groupedImagesByDate[imageDate].map((img, i) =>
                                <Grid item key={i} className={isMobile && classes.imageMobile}>
                                    <FadeInImage
                                        img={img}
                                        alt={`Vehicle Image ${i}`}
                                        onDelete={onDeleteConfirm}
                                        onEdit={onEditConfirm}
                                        imageTypes={imageTypes}
                                        changeImageTypeId={changeImageTypeId}
                                        existingImages={existingImages}
                                        showCarousel={true}
                                        imagesForCarousel={groupedImagesByDate}
                                        noteType={NoteEntityTypes.VEHICLE_IMAGE}
                                    />
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                )}
                {latestLoad?.id && <Grid container direction="column">
                    <Grid container className={classes.loadAttachments}>
                        <LoadAttachments groupId={latestLoad?.groupId} loads={[latestLoad]} placeholder={<></>} showLoading={false} hasTitle={true} />
                    </Grid>
                </Grid>}
                <VehiclePendingSyncCheckIns vehicleId={vehicleDetails?.id} style={{
                    width: 223,
                    maxWidth: '100%',
                    height: 120,
                }} />
            </Grid>

        </>)
}