import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Paper } from '@material-ui/core';
import { useState } from 'react';
import Authorize from '../../../../components/Authorize';
import { permissionProfiles } from '../../../../components/Authorize/permissionProfiles';
import NotificationProvider from '../../../../components/NotificationProvider';
import { useGetClientsWithConfigsQuery, useUpdateClientMutation } from '../../clientConfigSlice';
import { clientConfigSetup } from '../../clientConfigsSetup';
import EditContractPopupStyle from './EditContractPopupStyle';

const useStyles = EditContractPopupStyle;

const EditContractPopup = ({ contract, open, setOpen, clientConfig, setClientConfig, name }) => {

    let { data: clientsWithConfigs, error: clientsWithConfigsError, isLoading: isLoadingClientsWithConfigs, isFetching: isFetchingClientsWithConfigs } = useGetClientsWithConfigsQuery(null);
    let allConfigsForClient = clientsWithConfigs?.filter(c => c.id == clientConfig.id); //filtering by clientId  
    let dropdownValues = allConfigsForClient?.map((c) => ({ ...c, id: c.accountId }));

    const classes = useStyles();
    let [isSaving, setIsSaving] = useState(false);

    let [updateClient] = useUpdateClientMutation();

    const handleClose = () => {
        setClientConfig({ ...clientConfig, changed: false });
        setOpen(false);
    };

    const handleSave = async () => {
        const { clientLots, ...other } = clientConfig;
        setIsSaving(true);
        let result = await updateClient({ ...other, configToUpdate: contract.configType });
        if (result?.error) {
            NotificationProvider.error('Failed to update the contract');
        } else {
            NotificationProvider.success('Successfully updated the contract');
            handleClose();
        }
        setIsSaving(false);
    };

    const onConfigChange = (configName, key, val) => {
        if (configName) {
            setClientConfig({ ...clientConfig, changed: true, ["config"]: { ...clientConfig.config, [configName]: { ...clientConfig.config[configName], [key]: val } } });
        } else {
            setClientConfig({ ...clientConfig, changed: true, [key]: val });
        }
    }

    const onSubClientChange = (accountId) => {
        setClientConfig(allConfigsForClient?.find(a => a.accountId == accountId));
    }

    const allConfigs = clientConfigSetup(clientConfig, onConfigChange)
    const currentConfig = allConfigs?.find(c => c.configName === name);

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            fullWidth
            maxWidth='md'
        >
            <DialogTitle>
                <Grid container justifyContent='space-between'>
                    <div>{`${contract?.label} SLA`}</div>
                    {contract?.headerComponent}
                </Grid>
            </DialogTitle>
            <DialogContent>
                {isSaving && <CircularProgress className={classes.spinner} />}
                <Paper className={classes.formContainer}>
                    {/* {currentConfig.showSubClients && allConfigsForClient?.length > 1 &&
                        <Grid item xs={5} className={classes.subClient}>
                            <CustomInput
                                id="clientAccountId"
                                label="Sub Client"
                                value={clientConfig?.accountId}
                                elementType="dropdown"
                                onChange={val => onSubClientChange(parseInt(val))}
                                values={dropdownValues}
                                showEmpty
                                touched
                            />
                        </Grid>
                    } */}
                    {currentConfig?.component}
                </Paper>
            </DialogContent>
            <DialogActions>
                <div className={`${classes.btnContainer}`}>
                    <Authorize profile={permissionProfiles.CLIENT_CONFIG.CLIENT_CONFIG_CHANGE}>
                        <div className={classes.btnWrapper}>
                            <Button
                                onClick={handleClose}
                                className={classes.button}
                                variant="contained"
                            >
                                Close
                            </Button>
                        </div>
                        {clientConfig?.changed && <div className={classes.btnWrapper}>
                            <Button
                                onClick={handleSave}
                                disabled={isSaving}
                                className={classes.button}
                                variant="contained"
                                color="primary"
                            >
                                {isSaving ? "Saving..." : "Save"}
                            </Button>
                        </div>}
                    </Authorize>
                </div>
            </DialogActions>
        </Dialog>

    );
};

export default EditContractPopup;