import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from "@material-ui/core";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import CustomInput from "components/CustomInput";
import NotificationProvider from "components/NotificationProvider";
import { setClientId, setFormType } from "features/Forms/FormList/formStateSlice";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { useUpdateClientMutation } from "../clientConfigSlice";
import { clientConfigTypes, clientConfigTypeToName } from "../clientConfigsSetup";
import { ConfigParamNamesPerType, configTypesFormTypesMapping, ConfigurationTypesById, getConfigurationTypes, ParamNamesPerClientConfigType } from "./enums";

const ConfigurationTypeDropdown = ({ configType, clientConfig }) => {

    let [updateClient, { isLoading }] = useUpdateClientMutation();
    const configName = clientConfigTypeToName[configType];
    const [config, setConfig] = useState();
    const [type, setType] = useState();
    const [openDialog, setOpenDialog] = useState(false);

    useEffect(() => {
        if(clientConfig)
        {
            const configuration = clientConfig?.config?.[configName];
            setConfig(configuration);

            //setting type field: find all params that are true and then get the one that comes first
            const paramsOn = Object?.entries(configuration).filter(([key, value]) => value === true)
                            .map(([key]) => key);
            const lowestHierarchyConfig = ConfigParamNamesPerType
                        .filter(config => config.paramNames.some(param => paramsOn.includes(param)))
                        .sort((a, b) => a.hierarchy - b.hierarchy)[0];
            let type = lowestHierarchyConfig?.id ?? (configType != clientConfigTypes.ConditionalReport ? ConfigurationTypesById.SLA : null);
            setType(type);
        }
    }, [clientConfig, configName, openDialog]);

    const onChangeType = async (val) => {
        setType(val);
        let configToSave = {};

        if(val == ConfigurationTypesById.CUSTOM_FORM) { 
            setOpenDialog(true);
        } 
        else {
            // find param name to update
            let paramNamesObj = ConfigParamNamesPerType.find(c => c.id == +val);
            let possibleParamNames = ParamNamesPerClientConfigType.find(c => c.id == configType)?.paramNames;
            let paramName = paramNamesObj?.paramNames.find(name => possibleParamNames?.includes(name));

            if(!paramName)
            {
                if(configType == clientConfigTypes.Checkin)
                {
                    configToSave = { ...clientConfig?.[0]?.config, [configName]: { ...config, ['useCustomCheckin']: false,  ['usePave']: false } }
                }
                if(configType == clientConfigTypes.Checkout)
                {
                    configToSave = { ...clientConfig?.[0]?.config, [configName]: { ...config, ['useCustomCheckOut']: false } }
                }
            } else {
                if(paramName == 'useConditionalReport')
                {
                    configToSave = { ...clientConfig?.[0]?.config, [configName]: { ...config, [paramName]: true, ['useCustomConditionReport']: false } };
                } else {
                    configToSave = { ...clientConfig?.[0]?.config, [configName]: { ...config, [paramName]: true } };
                }
            }

            let result = await updateClient({ id: clientConfig?.id, config: configToSave, configToUpdate: configType });
            if (result?.error) {
                NotificationProvider.error('Failed to update');
            } else {
                NotificationProvider.success('Successfully updated the configuration type');
            }
        }
    }

    return (
        <>
            <Grid item xs={4}>
                <CustomInput
                    values={getConfigurationTypes(configType === clientConfigTypes.Checkin)}
                    value={type}
                    onChange={(val) => onChangeType(val)}
                    elementType={'dropdown'}
                    label={"Configuration Type"}
                    shrink
                    showEmpty
                />
            </Grid>
            <CustomReportPromptDialog open={openDialog} setOpen={setOpenDialog} clientId={clientConfig?.id} configType={configType}/>
        </>
    );
}

export default ConfigurationTypeDropdown;

const CustomReportPromptDialog = ({ open, setOpen, clientId, configType }) => {

    const dispatch = useDispatch()
    const history = useHistory();

    let formType = configTypesFormTypesMapping.find(mapping => mapping.configType === configType)?.formType;

    const handleNavigation = () => {
        dispatch(setClientId(clientId));
        dispatch(setFormType(formType))
        history.push('/forms');
    };

    return (
        <Dialog open={open}>
            <DialogTitle>Enable Custom Forms</DialogTitle>
            <DialogContent>
                {"To use custom forms for this client, you need to enable the option in the Forms Page and configure it there. Click below to proceed."}            </DialogContent>
            <DialogActions>
                <>
                    <Button 
                        onClick={() => {setOpen(false);}} 
                        variant="contained"
                        color="primary"
                    >
                        Cancel
                    </Button>

                    <Button 
                        endIcon={<ArrowForwardIcon />}
                        variant='outlined'
                        color='primary'
                        onClick={handleNavigation}
                    >
                        Forms Page
                    </Button>
                </>
            </DialogActions>
        </Dialog>
    )
}