import { Button, Grid, Paper } from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useDispatch, useSelector } from "react-redux";
import mobileBreakpoint from "../../../../../utils/mobileBreakpoint";
import { LotsCapacityStyle } from "./LotsCapacityStyle";
import DataTable from "../../../../../components/DataTable/DataTable";
import FilterService, { fieldTypes } from "services/filterService";
import useSortOptions from "hooks/useSortOptions";
import { useGetClientsQuery } from "features/clients/clientSlice";
import { orderBy } from "lodash";
import DataSearchBar from "components/DataSearchBar";
import DropdownFilter from "components/DropdownFilter";
import { resetFilters, setFilters } from "./LotsCapacityFiltersSlice";
import LoadingSpinner from "components/LoadingSpinner";
import { useState } from "react";
import { CapacityTableTypes, CapacityTableTypesList } from "features/lots/LotsEnums";
import { arrayToObject } from "utils/object-util";
import { useGetVehicleTypeCategoryQuery } from "features/vehicles/vehicleSlice";
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';

const LotsCapacityTables = ({ lotCapacity, setSelectedLot,
    searchedVal, searchLot
}) => {

    const classes = LotsCapacityStyle();
    let { data: clients, error: clientError, isLoading: isLoadingClients } = useGetClientsQuery(null);
    const { data: vehicleTypeCategories, isLoading: isLoadingVehicleTypeCategory } = useGetVehicleTypeCategoryQuery();

    const clientsLookup = arrayToObject(clients);
    const vehicleTypeCategoriesLookup = arrayToObject(vehicleTypeCategories);

    const isMobile = useMediaQuery(mobileBreakpoint);
    const dispatch = useDispatch();
    const [sortOptions, sortData] = useSortOptions();
    const { filters } = useSelector(state => state.lotsCapacityFilters); //check
    const [selectedTable, setSelectedTable] = useState(CapacityTableTypes.PER_CLIENT);

    const updateFilters = (prop, value) => {
        dispatch(setFilters({ ...filters, [prop]: { value } }));
    }
    const onDropFilter = (filterValue, prop) => {
        updateFilters(prop, filterValue);
    };

    const onResetFilters = () => {
        dispatch(resetFilters());
    };

    const capacityPerClient = lotCapacity?.capacityPerClient?.map(cpc => ({
        ...cpc,
        name: clientsLookup[cpc.clientId]?.name,
        rentedSpots: lotCapacity.rentedSpots || 0,
        availableSpots: lotCapacity.availableSpots || 0,
        totalOccupiedArea: lotCapacity.totalOccupiedArea || 0,
        availableArea: lotCapacity.availableArea || 0
    }))?.sort((a, b) => a?.name?.localeCompare(b?.name));

    const capacityPerAssetClass = lotCapacity?.capacityPerAssetClass?.map(cpac => ({
        ...cpac,
        name: vehicleTypeCategoriesLookup[cpac.assetClassId]?.name,
        rentedSpots: lotCapacity.rentedSpots || 0,
        availableSpots: lotCapacity.availableSpots || 0,
        totalOccupiedArea: lotCapacity.totalOccupiedArea || 0,
        availableArea: lotCapacity.availableArea || 0
    }))?.sort((a, b) => a?.name?.localeCompare(b?.name));
    
    const capacityPerClientPerAssetClass = lotCapacity?.capacityPerClientPerAssetClass?.map(cpcpac => ({
        ...cpcpac,
        name: `${vehicleTypeCategoriesLookup[cpcpac.assetClassId]?.name} (${clientsLookup[cpcpac.clientId]?.name})`,
        rentedSpots: lotCapacity.rentedSpots || 0,
        availableSpots: lotCapacity.availableSpots || 0,
        totalOccupiedArea: lotCapacity.totalOccupiedArea || 0,
        availableArea: lotCapacity.availableArea || 0
    }))?.sort((a, b) => a?.name?.localeCompare(b?.name));

    const tablesData = {
        [CapacityTableTypes.PER_CLIENT]: capacityPerClient,
        [CapacityTableTypes.PER_ASSET_CLASS]: capacityPerAssetClass,
        [CapacityTableTypes.PER_ASSET_CLASS_PER_CLIENT]: capacityPerClientPerAssetClass
    }

    const lotCapacityData = tablesData[selectedTable] || [];

    const columns = [
        { name: lotCapacity?.name, key: "name" },
        { name: "Assets Physicaly On Lot", key: "assetsPhysicalyOnLot" },
        { name: "Domiciled Assets", key: "domiciledAssets" },
        { name: "Rented Spots (Based On SLA)", key: "rentedSpots" },
        { name: "Used Spots", key: "usedSpots" },
        { name: "Available Spots", key: "availableSpots" },
        { name: "Total Occupied Area", key: "totalOccupiedArea" },
        { name: "Available Area", key: "availableArea" }
    ];

    let filterConfig = [];

    if (selectedTable !== CapacityTableTypes.PER_CLIENT) {
        filterConfig.push({ type: fieldTypes.TEXT, size: 2, title: 'Asset Class', key: 'assetClassId', values: vehicleTypeCategories });
    }

    if (selectedTable !== CapacityTableTypes.PER_ASSET_CLASS) {
        filterConfig.push({ type: fieldTypes.TEXT, size: 2, title: 'Client', key: 'clientId', values: clients });
    }

    let filteredMappings = FilterService.filter(filters, searchedVal, lotCapacityData, filterConfig);

    const onSelectTable = (val) => {
        onResetFilters();
        setSelectedTable(+val);
    }

    const mapExportData = v => ({
        [lotCapacity?.name]: v.name,
        ['Assets Physicaly On Lot']: v.assetsPhysicalyOnLot,
        ['Domiciled Assets']: v.domiciledAssets,
        ['Rented Spots (Based On SLA)']: v.rentedSpots,
        ['Used Spots']: v.usedSpots,
        ['Available Spots']: v.availableSpots,
        ['Total Occupied Area']: v.totalOccupiedArea,
        ['Available Area']: v.availableArea,
    });

    const isLoading = isLoadingClients || isLoadingVehicleTypeCategory;

    return (
        <>
            <Paper className={`${classes.header} ${isMobile ? classes.headerMobile : ""}`} elevation={1}>
                <LoadingSpinner isLoading={isLoading} />
                <Grid container spacing={1} className={classes.headerContainer}>
                    <Grid item xs={1}>
                        <Button startIcon={<ChevronLeftIcon />} onClick={() => setSelectedLot(null)}>Back</Button>
                    </Grid>
                    <Grid item xs={6}>
                        <DataSearchBar
                            searchVal={searchedVal}
                            rows={lotCapacity}
                            onSearch={searchLot}
                        />
                    </Grid>
                    <Grid item xs={5} >
                        <Grid item>
                            <DropdownFilter
                                title="Select Table to View"
                                values={CapacityTableTypesList}
                                value={selectedTable}
                                onSelect={onSelectTable}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
            <Grid container spacing={2} className={classes.listContainer}>
                <DataTable
                    columns={columns}
                    rows={orderBy(
                        filteredMappings,
                        sortOptions.columnToSort,
                        sortOptions.sortDirection
                    )}
                    rowIdentifier='id'
                    onSort={sortData}
                    sortDirection={sortOptions.sortDirection}
                    columnToSort={sortOptions.columnToSort}
                    noItemsMessage='There are no data matching this criteria to display'
                    title={`Lot Capacity ${lotCapacity?.name}`}
                    filters={filters}
                    onDropFilter={onDropFilter}
                    dropDownsConfig={filterConfig}
                    withFilterPanel={true}
                    onResetFilters={onResetFilters}
                    mapExportData={mapExportData}
                />
            </Grid>
        </>
    )
}

export default LotsCapacityTables;