import { Grid, Paper } from "@material-ui/core";
import ExpandLessRoundedIcon from '@mui/icons-material/ExpandLessRounded';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import { IconButton, ListItemIcon, ListItemText, Menu, MenuItem } from "@mui/material";
import { permissionProfiles } from "components/Authorize/permissionProfiles";
import { useGetConfigurationByVehicleQuery } from "features/vehicles/configurationSlice";
import { IconAccessCode, IconCar, IconCheckIn, IconCheckOut, IconConditionReportOption } from "icons";
import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { theme } from "styles/theme";
import { vehicleDetailsPageStyle } from "../VehicleDetailsPageStyle";
import { CustomFormSyncProgress } from "features/vehicles/VehicleCustomForm/syncProgress/CustomFormSyncProgress";
import { VehiclePendingSyncCheckIns } from "features/vehicles/VehicleCustomForm/syncProgress/VehiclePendingSyncCheckIns";

export const vehicleTabTypes = {
    DETAILS: 1,
    ACCESS: 2,
    CHECK_IN: 3,
    CHECK_OUT: 4,
    CONDITION_REPORT: 5
}

export const vehicleTabslist = {
    [vehicleTabTypes.DETAILS]: 'details',
    [vehicleTabTypes.ACCESS]: 'access',
    [vehicleTabTypes.CHECK_IN]: 'checkin',
    [vehicleTabTypes.CHECK_OUT]: 'checkout',
    [vehicleTabTypes.CONDITION_REPORT]: 'conditionreport'
}

export const vehicleTabMapping = {
    'details': vehicleTabTypes.DETAILS,
    'access': vehicleTabTypes.ACCESS,
    'checkin': vehicleTabTypes.CHECK_IN,
    'checkout': vehicleTabTypes.CHECK_OUT,
    'conditionreport': vehicleTabTypes.CONDITION_REPORT
};

export const VehicleTabs = ({ vehicleId, selected = 0, setSelected, setSelectedKey, vehicleDetails }) => {
    const classes = vehicleDetailsPageStyle();
    const history = useHistory();
    let { tab } = useParams();
    const tabFromRoute = vehicleTabMapping[tab] || vehicleTabTypes.DETAILS;
    const [selectedTab, setSelectedTab] = useState(tabFromRoute);

    let { data: configuration, error: configurationError, isLoading: isLoadingConfiguration } = useGetConfigurationByVehicleQuery(vehicleId);

    const vehicleTabs = [
        {
            id: vehicleTabTypes.DETAILS,
            name: 'Asset Details',
            icon: <IconCar />
        },
        {
            id: vehicleTabTypes.ACCESS,
            name: 'Access Groups',
            permission: permissionProfiles.ACCESS.MANAGE_ACCESS,
            icon: <IconAccessCode />
        }
    ]

    if (configuration?.useCustomCheckin) {
        vehicleTabs.push({
            id: vehicleTabTypes.CHECK_IN,
            name: 'Check In',
            icon: <IconCheckIn />
        });
    }

    if (configuration?.useCustomCheckOut) {
        vehicleTabs.push({
            id: vehicleTabTypes.CHECK_OUT,
            name: 'Check Out',
            icon: <IconCheckOut />
        });
    }

    if (configuration?.useCustomConditionReport) {
        vehicleTabs.push({
            id: vehicleTabTypes.CONDITION_REPORT,
            name: 'Condition Report',
            icon: <IconConditionReportOption />
        });
    }

    const getSelectedTab = () => {
        var tabToReturn = tabFromRoute;
        if (tabFromRoute === vehicleTabTypes.CHECK_IN && !configuration?.useCustomCheckin) {
            tabToReturn = vehicleTabTypes.DETAILS;
        }
        if (tabFromRoute === vehicleTabTypes.CHECK_OUT && !configuration?.useCustomCheckOut) {
            tabToReturn = vehicleTabTypes.DETAILS;
        }
        if (tabFromRoute === vehicleTabTypes.CONDITION_REPORT && !configuration?.useCustomConditionReport) {
            tabToReturn = vehicleTabTypes.DETAILS;
        }
        setSelected(tabToReturn)
        return tabToReturn;
    };

    useEffect(() => {
        if (!isLoadingConfiguration) {
            setSelectedTab(getSelectedTab());
            if (tab && !vehicleTabMapping[tab]) {
                history.push(`/assets/detail/${vehicleId}`);
            }
        }
    }, [isLoadingConfiguration, configuration, tabFromRoute]);

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const selectTab = (tab) => {
        history.push(`/assets/detail/${vehicleId}/${vehicleTabslist[tab]}`);
        handleClose();
    }

    return (
        <Paper className={`${classes.tabsWrapper}`} >
            <Grid container justifyContent="space-between">
                <Grid item className={classes.tabItem}>
                    <div className={classes.itemIcon}>
                        <IconCar width={20} height={20} />
                    </div>
                    <h2>
                        {vehicleDetails?.descriptor ? vehicleDetails?.descriptor : vehicleDetails?.assetId ?? vehicleDetails?.licensePlate}
                    </h2>

                </Grid>
                <Grid item className={classes.tabItem}>
                    <VehiclePendingSyncCheckIns vehicleId={vehicleDetails?.id} />
                    <Grid container justifyContent="space-between" alignItems="center" className={classes.tabSelecter}
                        onClick={handleClick} >
                        <Grid item>
                            {vehicleTabs?.find(t => t.id == selectedTab)?.name}
                        </Grid>
                        <Grid item>
                            <IconButton size="small">
                                {open ? <ExpandLessRoundedIcon
                                    style={{ color: theme.palette.primary.main }}
                                /> : <ExpandMoreRoundedIcon style={{ color: theme.palette.primary.main }} />}
                            </IconButton>
                        </Grid>
                    </Grid>
                    <Menu
                        id="tabs-menu"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        PaperProps={{
                            style: {
                                width: "300px",
                            }
                        }}
                    >
                        {vehicleTabs.map(tab =>
                            <MenuItem onClick={() => selectTab(tab?.id)}>
                                <div className={classes.menuItem}>
                                    <ListItemIcon>{tab.icon}</ListItemIcon>
                                    <ListItemText><div className={classes.menuItemText}>{tab.name}</div></ListItemText>
                                </div>
                            </MenuItem>
                        )}
                    </Menu>
                </Grid>
            </Grid>
        </Paper>
    )
}