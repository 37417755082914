import { Grid } from "@material-ui/core";
import DateRange from "components/DateRange";
import LotsDropdown from "components/SharedDropdowns/LotsDropDown";
import { useGetAllActivityItemsQuery, useGetAllActivityTypesQuery, useGetMechanicalActivitiesQuery } from "features/activity/activitySlice";
import { useGetLotsQuery } from "features/lots/lotSlice";
import { IconDatePicker, IconLot } from "icons";
import { groupBy } from "lodash";
import { useState } from "react";
import { useSelector } from "react-redux";
import FilterService, { fieldTypes } from "services/filterService";
import { arrayToObject } from "utils/object-util";
import WidgetTitle from "../WidgetTitle/WidgetTitle";
import ActivitiesCount from "./ActivitiesCount";
import ActivityWidgetTabs from "./ActivityWidgetTabs";
import { activitiesStyle } from "./style";

const cleaning = 'Cleaning';
const mechanicals = 'Mechanicals';
const inspection = 'Inspection';

const ActivitiesWidget = ({ tooltipInfo, mechActivities = null, workorderWidget }) => {
    const classes = activitiesStyle();

    let { data: mechanicalActivities, error: mechanicalActivitiesError, refetch, isFetching: isLoadingMechanicalActivities } = useGetMechanicalActivitiesQuery({ skip: !!mechActivities });
    let { data: activityItems, error: activityItemsError, isFetching: isLoadingActivityItems } = useGetAllActivityItemsQuery();
    let { data: lots, error: lotError, isLoading: isLoadingLots, refetch: refetchLots } = useGetLotsQuery();
    let { data: activityTypes, error: activityTypesError, isFetching: isLoadingActivityTypes } = useGetAllActivityTypesQuery({ skip: !workorderWidget });
    lots = lots || [];

    const activityItemsLookup = arrayToObject(activityItems)

    const { dashboardFilters } = useSelector(state => state.fleetManagementDashboardState);
    const [selectedType, setSelectedType] = useState(mechanicals);
    
    const cleaningType = activityTypes?.find(a => a.name == cleaning);
    const mechanicalsType = activityTypes?.find(a => a.name == mechanicals);
    const inspectionType = activityTypes?.find(a => a.name == inspection);
    
    let activities = (mechActivities ?? mechanicalActivities) || [];

    activities = activities?.filter(
        activity => activityItemsLookup?.[activity.activityItemId]?.typeTag != 1 &&
            activityItemsLookup?.[activity.activityItemId]?.typeTag != 6 &&
            (!dashboardFilters?.clientId || dashboardFilters?.clientId == activity?.clientId)
    ) || [];

    const [filters, setFilters] = useState({
        'lotId': { value: [] },
        'activityDate': { value: null }
    })

    const onDropFilter = (filterValue, prop) => {
        setFilters({ ...filters, [prop]: { value: filterValue } });
    };

    let filterConfig = [
        { type: fieldTypes.ASSOCIATED_LOT, size: 3, title: 'Lot', key: 'lotId', values: lots },
        { type: fieldTypes.DATERANGE, size: 3, title: 'Date', key: 'activityDate', values: [] },
    ];

    let filteredData = FilterService.filter(filters, null, activities, filterConfig);
    
    filteredData = selectedType == 'All' ? filteredData : filteredData?.filter(a => {
        if (selectedType == cleaning && a.activityTypeId == cleaningType?.id) return true;
        if (selectedType == mechanicals && a.activityTypeId == mechanicalsType?.id) return true;
        if (selectedType == inspection && a.activityTypeId == inspectionType?.id) return true;
        return false;
    });

    const dataGrouped = groupBy(filteredData, 'activityItemId');

    const [selectedTab, setSelectedTab] = useState(0);

    const loading = isLoadingMechanicalActivities || isLoadingLots;

    return <Grid container direction="column" className={classes.graphWrapper}>
        <WidgetTitle
            title='Activity Counts'
            info={tooltipInfo}
            loading={loading}
            filtersList={filters}
            filters={
                <Grid container spacing={1}>
                    {!workorderWidget && <><Grid className={classes.filterIcon} item xs={2}>
                        <IconLot />
                    </Grid>
                    <Grid item xs={10}>
                        <LotsDropdown
                            title='Lot'
                            identifier='lot'
                            values={lots}
                            onSelect={(val) => onDropFilter(val, 'lotId')}
                            value={filters?.['lotId']?.value}
                            showEmpty={true}
                            multiple={true}
                        />
                    </Grid></>}
                    <Grid className={classes.filterIcon} item xs={2}>
                        <IconDatePicker />
                    </Grid>
                    <Grid item xs={10}>
                        <DateRange
                            title="Activity Date"
                            identifier='activityDate'
                            onSelect={(val) => onDropFilter(val, 'activityDate')}
                            dateValue={filters?.['activityDate']?.value}
                            filterButton
                        />
                    </Grid>
                </Grid>
            }
            visibleFilters={workorderWidget}
            selectedType={selectedType}
            setSelectedType={setSelectedType}
        />
        <Grid item style={{ height: 300, paddingRight: '0.5rem' }}>
            <ActivityWidgetTabs selectedTab={selectedTab} setSelectedTab={setSelectedTab} />
            <ActivitiesCount activities={dataGrouped} count={selectedTab == 0} fullList={workorderWidget} />
        </Grid>
    </Grid>
}
export default ActivitiesWidget;