import { Dialog, DialogContent, DialogTitle, Grid, Switch } from '@material-ui/core';
import Authorize from 'components/Authorize';
import { useUpdateCheckInMutation } from 'features/clients/clientConfigs/CheckIn/CheckInConfigSlice';
import SingleCheckInConfig from 'features/clients/clientConfigs/CheckIn/SingleCheckInConfig';
import { useEffect, useState } from 'react';
import EditWorkFlow from './EditWorkFlow';

const CheckInSLABridge = ({ contract, open, clientConfig, handleClose }) => {
    const [configFields, setConfigFields] = useState({});
    const [updateCheckIn] = useUpdateCheckInMutation();

    useEffect(() => {
        if (clientConfig && open) {
            const conditionalReportConfigParams = clientConfig?.config?.conditionalReportConfig;
            setConfigFields({ ...configFields, ...conditionalReportConfigParams })
        }
    }, [clientConfig, open])

    const onChangeConfigField = (field, value) => {
        setConfigFields({ ...configFields, [field]: value });
    }


    const field = (fieldName, label, component, icon, profile) => (
        <Authorize profile={profile}>
            <div style={{ display: 'flex', alignItems: 'center' }} >
                <div style={{ width: '100%', fontStyle: "italic", fontSize: 13.5, color: "#b0bac1", fontWeight: "bold" }}>{label} </div>
                <div >
                    {component}
                </div>
            </div>
        </Authorize >
    )

    const createHTMLByOptionSelected = () => {
        if (!configFields?.useSinglePageCheckIn) {
            return <EditWorkFlow
                contract={contract}
                open={open}
                clientConfig={clientConfig}
                name={contract.configName}
                handleClose={handleClose}
            />;
        }
        else if (configFields?.useSinglePageCheckIn) {
            return <SingleCheckInConfig clientConfig={clientConfig} contract={contract} handleClose={handleClose} open={open} onSave={onSaveSingleConfig} />;
        }
    }

    const onSaveSingleConfig = async (params) => {
        params.useSinglePageCheckIn = configFields?.useSinglePageCheckIn;
        params.useConditionalReport = configFields?.useConditionalReport;
        params.priceByReport = configFields?.priceByReport;
        return await updateCheckIn(params);
    }

    return (
        <>
            {
                <Dialog
                    open={open}
                    onClose={handleClose}
                    fullWidth
                    maxWidth={configFields?.useSinglePageCheckIn ? 'xl' : 'md'}
                >
                    <DialogTitle>
                        <div>{`${contract?.label} SLA`}</div>
                        {/* <Grid container xl>
                                {field('useSinglePageCheckIn', 'Use Single Page Check In', <Authorize profile={undefined}>
                                <Switch
                                    color="primary"
                                    checked={configFields?.useSinglePageCheckIn != false}
                                    onChange={(e) => onChangeConfigField('useSinglePageCheckIn', e.target.checked)}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                /></Authorize>, undefined, undefined)}
                            </Grid> */}
                    </DialogTitle>
                    <DialogContent>
                        {createHTMLByOptionSelected()}
                    </DialogContent>
                </Dialog>
            }
        </>
    );
};

export default CheckInSLABridge;


