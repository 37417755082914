import { Button, Grid, IconButton, Menu, MenuItem, Tooltip } from "@material-ui/core";
import HistoryIcon from '@material-ui/icons/History';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { IconRedo, IconSection, IconUndo } from "icons";
import { useRef, useState } from "react";
import { formTypeOptions } from "../formBuilderConsts";
import { FormBuilderStyles } from "../style";

const FormActions = ({
    form,
    onAddSection,
    onSave,
    saving,
    redo,
    undo,
    redoEnabled,
    undoEnabled,
    openVersions,
    selectedVersion,
    clientId,
    formType
}) => {

    const classes = FormBuilderStyles();

    const [openSaveMenu, setOpenSaveMenu] = useState(false);

    const ref = useRef();

    const save = (asDraft) => {
        setOpenSaveMenu(false);
        onSave(asDraft);
    }

    const formTypeSetup = formTypeOptions.find(f => f.id == formType)

    return (
        <Grid container className={classes.formActions} >
           
            <Tooltip title={`Undo`}>
                <IconButton disabled={!undoEnabled} onClick={() => undo()}>
                    <IconUndo />
                </IconButton>
            </Tooltip>
            <Tooltip title={`Redo`}>
                <IconButton disabled={!redoEnabled} onClick={() => redo()}>
                    <IconRedo />
                </IconButton>
            </Tooltip>
            <Tooltip title={`View History`}>
                <IconButton onClick={() => openVersions()} >
                    <HistoryIcon />
                </IconButton>
            </Tooltip>
            <Tooltip title={`Add Section`}>
                <IconButton onClick={() => onAddSection()}>
                    <IconSection />
                </IconButton>
            </Tooltip>
            {/* <Tooltip title={`Save`}>
               
                    <Button startIcon={<CheckOutlinedIcon />} disabled={saving} onClick={onSave} color="primary" variant="outlined">
                        {saving ? 'Saving...' : 'Save'}
                    </Button>
                </div>
            </Tooltip> */}
            <div className={classes.saveFormBtn} >
                <Button
                    disabled={selectedVersion || saving}
                    ref={ref}
                    id="form-builder-save"
                    aria-controls={openSaveMenu ? 'form-builder-save' : undefined}
                    aria-haspopup="true"
                    aria-expanded={openSaveMenu ? 'true' : undefined}
                    variant="outlined"
                    disableElevation
                    onClick={() => setOpenSaveMenu(!openSaveMenu)}
                    endIcon={<KeyboardArrowDownIcon />}
                >
                    {saving ? 'Saving...' : 'Save'}
                </Button>
                <Menu
                    id="save-menu"
                    anchorEl={ref.current}
                    open={openSaveMenu}
                    onClose={() => setOpenSaveMenu(false)}
                    MenuListProps={{
                        'aria-labelledby': 'save-menu',
                    }}
                    getContentAnchorEl={null}
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                    transformOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                >
                    <MenuItem onClick={() => save(true)}>
                        Save as draft
                    </MenuItem>
                    <MenuItem onClick={() => save()}>
                        Save
                    </MenuItem>
                </Menu>
            </div>
        </Grid >
    );
}
export default FormActions; 