import { Grid, Switch } from "@material-ui/core";
import { mechanicalConfigStyles } from "./style";
import CustomInput from "components/CustomInput";
import Authorize from "components/Authorize";
import { IconDollar, IconPercent } from "icons";
import { permissionProfiles } from "components/Authorize/permissionProfiles";
import VehicleStatusView from "features/vehicles/VehicleStatus/VehicleStatusView";

const ConditionReportConfig = ({ useConditionalReport, priceByReport, defaultCheckinStatus, onChangeField, clientConfig, useSinglePageCheckIn = false }) => {

    const classes = mechanicalConfigStyles();

    const priceField = (fieldName, label, value, icon) => (
        field(
            fieldName,
            label,
            <CustomInput
                value={value}
                onChange={(val) => onChangeField(fieldName, parseFloat(val))}
                icon={icon ? icon : <IconPercent />}
                elementType="input"
                numberOnly
            />, undefined, permissionProfiles.CLIENT_CONFIG.CLIENT_CONFIG_LOT_PRICING
        )
    )

    const onChangeSinglePage = async (val) => {
        if (useConditionalReport == true && val == true) {
            await onChangeField('useConditionalReport', false);
        } 
        await onChangeField('useSinglePageCheckIn', val);
    }


    const field = (fieldName, label, component, icon, profile) => (
        <Authorize profile={profile}>
            <div style={{ display: 'flex', alignItems: 'center' }} >
                <div style={{ width: '20%' }}>{label} </div>
                <div >
                    {component}
                </div>
            </div>
        </Authorize >
    )


    return <>
        <Grid container direction='column' spacing={1} xs={8}>
            {/* <Grid item>
                {field('useConditionalReport', 'Use Conditional Report', <Authorize profile={undefined}>
                    <Switch
                        color="primary"
                        checked={useConditionalReport != false}
                        onChange={(e) => onChangeField('useConditionalReport', e.target.checked)}
                        inputProps={{ 'aria-label': 'controlled' }}
                    /></Authorize>, undefined, undefined)}
            </Grid> */}
            {/* <Grid item>
                {field('useSinglePageCheckIn', 'Use Single Page Check In', <Authorize profile={undefined}>
                    <Switch
                        color="primary"
                        checked={useSinglePageCheckIn != false}
                        onChange={(e) => onChangeSinglePage(e.target.checked)}
                        inputProps={{ 'aria-label': 'controlled' }}
                    /></Authorize>, undefined, undefined)}
            </Grid> */}
            <Grid item>
                {priceField('priceByReport', 'Report Price', priceByReport, <IconDollar />)}
            </Grid>
            <Grid item>
                {field('defaultCheckinStatus', 'Default check-in status', <Authorize profile={permissionProfiles.CLIENT_CONFIG.CHECKIN_SLA_ACTIONS}>
                    <VehicleStatusView
                        clientId={clientConfig.id}
                        label={null}
                        value={defaultCheckinStatus}
                        onChange={val => onChangeField("defaultCheckinStatus", +val)}
                        readonly={false}
                    />
                </Authorize>)}
            </Grid>
        </Grid>
    </>
}

export default ConditionReportConfig;