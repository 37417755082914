import { modes } from "../modes";
import { permissions } from "../permissions/permissions";
import { roles } from "../roles";

export const dashboardPermissionProfiles = {
    WASH_SLA: {
        [modes.EDIT]: [roles.OWNER, roles.ADMIN, roles.SUPERVISOR, roles.ACCOUNT_MANAGER],
    },
    KPI_DASHBOARD_VIEW: {
        [modes.EDIT]: [roles.OWNER],
        [modes.EDIT_PERMISSION]: [permissions.DASHBOARD.KPI_DASHBOARD_VIEW],
    },
    KPI_DASHBOARD_VIEW_TAB_OVERVIEW: {
        [modes.EDIT]: [roles.OWNER],
        [modes.EDIT_PERMISSION]: [permissions.DASHBOARD.KPI_DASHBOARD_VIEW_TAB_OVERVIEW],
    },
    KPI_DASHBOARD_VIEW_TAB_INSPECTION: {
        [modes.EDIT]: [roles.OWNER],
        [modes.EDIT_PERMISSION]: [permissions.DASHBOARD.KPI_DASHBOARD_VIEW_TAB_INSPECTION],
    },
    DISPATCH_DASHBOARD_VIEW: {
        [modes.EDIT]: [roles.OWNER],
        [modes.EDIT_PERMISSION]: [permissions.DASHBOARD.DISPATCH_DASHBOARD_VIEW],
    },
    WASH_DASHBOARD_COMPLIANCE_GRAPH: {
        [modes.EDIT]: [roles.OWNER, roles.ADMIN, roles.SUPERVISOR, roles.ACCOUNT_MANAGER],
        [modes.EDIT_PERMISSION]: [permissions.DASHBOARD.WASH_DASHBOARD_COMPLIANCE_GRAPH],
    },
    MECHANICAL_DASHBOARD_VIEW: {
        [modes.EDIT]: [roles.OWNER],
        [modes.EDIT_PERMISSION]: [permissions.DASHBOARD.MECHANICAL_DASHBOARD_VIEW],
    },
    VIEW_REPORTS_SUB_MENU: {
        [modes.EDIT]: [roles.OWNER, roles.ACCOUNT_MANAGER, roles.ADMIN, roles.SUPERVISOR, roles.MECHANIC, roles.ACCOUNT_MANAGER]
    },
    ONE_ON_ONE: {
        [modes.EDIT]: [roles.OWNER, roles.ACCOUNT_MANAGER, roles.SUPERVISOR]
    },
    CREATE_INSPECTION: {
        [modes.EDIT]: [roles.OWNER, roles.ACCOUNT_MANAGER, roles.ADMIN, roles.SUPERVISOR,]
    },
    MECHANICAL_VIEW: {
        [modes.EDIT]: [roles.OWNER, roles.ACCOUNT_MANAGER, roles.ADMIN, roles.SUPERVISOR,],
        [modes.READONLY]: [roles.CLIENT, roles.MECHANIC]
    },
    WORKORDER_SLA: {
        [modes.EDIT]: [roles.OWNER, roles.ADMIN, roles.SUPERVISOR, roles.ACCOUNT_MANAGER]
    },
    WORKORDER_CLIENT_FILTER: {
        [modes.EDIT]: [roles.OWNER, roles.ADMIN, roles.SUPERVISOR, roles.MECHANIC, roles.ACCOUNT_MANAGER]
    },
    CREATE_WORK_ORDER: {
        [modes.EDIT]: [roles.OWNER, roles.ACCOUNT_MANAGER, roles.MECHANIC],
        [modes.EDIT_PERMISSION]: [permissions.DASHBOARD.MECHANICAL_DASHBOARD_CREATE_WO, permissions.VEHICLE.ASSETS_DETAIL_PAGE_ADD_ACTIVITY_LOG],
    },
    MECHANICAL_DASHBOARD_IMPORT_ACTIVITIES: {
        [modes.EDIT]: [roles.OWNER, roles.ACCOUNT_MANAGER, roles.MECHANIC],
        [modes.EDIT_PERMISSION]: [permissions.DASHBOARD.MECHANICAL_DASHBOARD_IMPORT_ACTIVITIES],
    },
    MECHANICAL_DASHBOARD_CAN_SEE_FLAG: {
        [modes.EDIT]: [roles.OWNER],
        [modes.EDIT_PERMISSION]: [permissions.DASHBOARD.MECHANICAL_DASHBOARD_CAN_SEE_FLAG],
    },
    MECHANICAL_DASHBOARD_EDIT_FLAG: {
        [modes.EDIT]: [roles.OWNER],
        [modes.EDIT_PERMISSION]: [permissions.DASHBOARD.MECHANICAL_DASHBOARD_EDIT_FLAG],
    },
    MECHANICAL_DASHBOARD_VIEW_NOTE: {
        [modes.EDIT]: [roles.OWNER],
        [modes.EDIT_PERMISSION]: [permissions.DASHBOARD.MECHANICAL_DASHBOARD_VIEW_NOTE],
    },
    MECHANICAL_DASHBOARD_EDIT_NOTE: {//This permission is not working correctly
        [modes.EDIT]: [roles.OWNER],
        [modes.EDIT_PERMISSION]: [permissions.DASHBOARD.MECHANICAL_DASHBOARD_EDIT_NOTE],
    },
    SHOW_REPORT_SUB_MENU: {
        [modes.EDIT]: [roles.OWNER, roles.ACCOUNT_MANAGER, roles.SUPERVISOR]
    },
    SHOW_SLA_SUB_MENU: {
        [modes.EDIT]: [roles.OWNER, roles.ACCOUNT_MANAGER],
        [modes.EDIT_PERMISSION]: [
            permissions.REPORTS.STORAGE,
            permissions.REPORTS.VIEW_COST_CODES,
            permissions.INVOICES.READ_TRANSPORTATION_INVOICE,
            permissions.INVOICES.READ_STORAGE_INVOICE,
            permissions.INVOICES.READ_MECHANICAL_INVOICE
        ]
    },
    FLEET_MANAGEMENT_DASHBOARD_VIEW: {
        [modes.EDIT]: [roles.OWNER],
        [modes.EDIT_PERMISSION]: [permissions.DASHBOARD.FLEET_MANAGEMENT_DASHBOARD_VIEW],
    },
}