export const LotTypeEnum = {
    SERVICE_PROVIDER: 1,
    OEM_SERVICE: 2,
    THIRD_PARTY: 3,
    STANDARD: 4
};

export const LotTypeEnumList = [
    { id: 1, name: "Service Provider" },
    { id: 2, name: "OEM Service Provider" },
    { id: 3, name: "3rd Party Lot" },
    { id: 4, name: "Standard Lot" }
];

export const LotTypeEnumListOptions = [
    { id: '1', name: "Service Provider" },
    { id: '2', name: "OEM Service Provider" },
    { id: '3', name: "3rd Party Lot" },
    { id: '4', name: "Standard Lot" }
];

export const ScreenViewTypes = {
    MAP: 1,
    LIST: 2,
    CAPACITY: 3,
};

export const CapacityTableTypes = {
    PER_CLIENT: 1,
    PER_ASSET_CLASS: 2,
    PER_ASSET_CLASS_PER_CLIENT: 3,
};

export const CapacityTableTypesList = [
    {
        id: CapacityTableTypes.PER_CLIENT,
        name: "Capacity Per Client"
    },
    {
        id: CapacityTableTypes.PER_ASSET_CLASS,
        name: "Capacity Per Asset Class"
    },
    {
        id: CapacityTableTypes.PER_ASSET_CLASS_PER_CLIENT,
        name: "Capacity Per Asset Class Per Client"
    },
];