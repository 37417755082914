import { Button, Grid, Paper } from "@material-ui/core";
import NotificationProvider from "components/NotificationProvider";
import { useUpdateFormResponseMutation } from "features/Forms/CustomReport/customReportSlice";
import { formTypes, questionTypes } from "features/Forms/formBuilder/formBuilderConsts";
import { useEffect, useState } from "react";
import { arrayToObject } from "utils/object-util";
import { Question } from "./components/Question";
import { EditFormOutputStyle } from "./style";

export const EditFormOutput = ({ customFormResponse, lotId, formType }) => {
    const classes = EditFormOutputStyle();

    const [updateForm, { isLoading: saving }] = useUpdateFormResponseMutation();

    const [formAnswers, setFormAnswers] = useState({});

    const allQuestions = customFormResponse?.form?.sections?.flatMap(section => section.questions)?.filter(q => q.questionType != questionTypes.Signature);
    const answerLookup = arrayToObject(customFormResponse?.answers?.filter(q => q.questionType != questionTypes.Signature), 'questionId');

    useEffect(() => {
        setFormAnswers(answerLookup)
    }, [customFormResponse?.answers])

    const resetForm = () => {
        setFormAnswers(answerLookup);
    }

    const handleSave = async () => {
        let result = await updateForm({
            ...customFormResponse,
            clientId: customFormResponse?.clientAccountId,
            answers: Object.values(formAnswers),
            lotId: lotId
        });
        if (result?.error) {
            NotificationProvider.error('Failed to update the form');
        } else {
            NotificationProvider.success('Successfully updated the form');
        }
    };

    const isSaving = saving;

    const isQuestionValid = (q) => {
        switch (q?.questionType) {
            case questionTypes.Image:
                return (formAnswers?.[q.id]?.pictureObj || formAnswers?.[q.id]?.pictureUrl) ? true : false;
            case questionTypes.MultipleChoice:
                return (formAnswers?.[q.id]?.multipleChoiceValue && formAnswers?.[q.id]?.multipleChoiceValue != "[]") ? true : false;
            case questionTypes.Freetext:
                return (formAnswers?.[q.id]?.freetextValue && formAnswers?.[q.id]?.freetextValue != `""`) ? true : false;
            case questionTypes.Date:
                return (formAnswers?.[q.id]?.dateValue && formAnswers?.[q.id]?.dateValue != `""`) ? true : false;
            case questionTypes.Dropdown:
                return (formAnswers?.[q.id]?.dropdownValue && formAnswers?.[q.id]?.dropdownValue != `""`) ? true : false;
            default:
                return (formAnswers?.[q.id]?.values && formAnswers?.[q.id]?.values != `""`) ? true : false
        }
    }

    const isValid = () => {
        return allQuestions?.every(q => !q.required || isQuestionValid(q))
    }

    return (
        <>
            <Paper variant='outlined' className={classes.reportContainer}>
                <Grid container alignItems='center' justifyContent="space-between" style={{ padding: '1em', background: '#efefef' }}>
                    <Grid item >
                        <div>{`Edit ${formType == formTypes.Checkin ? "Check-In" : formType == formTypes.Checkout ? "Check-out" : "Condition Report"}`}</div>
                    </Grid>
                </Grid>
                <form id="editFormOutput" className={`${classes.form}`}>
                    {allQuestions?.map((q, index) => <>
                        <Question
                            key={q.id}
                            question={q}
                            formAnswers={formAnswers}
                            setFormAnswers={setFormAnswers}
                        />
                    </>)}
                </form>
                <Paper className={classes.reportActions} >
                    <Grid container justifyContent="flex-end" spacing={2}>
                        <Grid item>
                            <Button onClick={() => resetForm()} variant="outlined" color="primary" disabled={isSaving}>
                                Cancel
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button
                                onClick={handleSave}
                                color="primary"
                                variant="contained"
                                disabled={isSaving || !isValid()}
                            >
                                {isSaving ? 'Saving...' : 'Save'}
                            </Button>
                        </Grid>
                    </Grid>
                </Paper>
            </Paper>
        </>
    )
}