import { Box, Button, Dialog, DialogContent, DialogTitle, Grid, Paper, Tab, Tabs } from "@material-ui/core";
import configStyles from "../configStyles";
import CheckInConfigStyle from "./CheckInConfigStyle";
import CheckInGeneralData from "./CheckInGeneralData";
import PropTypes from 'prop-types';
import React, { useEffect, useState } from "react";
import Authorize from "components/Authorize";
import { permissionProfiles } from "components/Authorize/permissionProfiles";
import NotificationProvider from "components/NotificationProvider";
import { useUpdateCheckInMutation } from "./CheckInConfigSlice";
import ClientVehicleTypes from "features/clients/contracts/EditContractPopup/EditWorkFlowPopup/components/ClientVehicleTypes/ClientVehicleTypes";
import { useSelector } from "react-redux";
import { TabsWrapper } from "components/TabsWrapper/TabsWrapper";
import ConditionReportConfig from "./ConditionReportConfig";
import { useGetDefaultStatusByClientIdQuery } from "features/clients/clientConfigSlice";
import CheckInGeneralDataSinglePage from "./CheckInGeneralDataSinglePage";
import ConfigurationTypeDropdown from "features/clients/contracts/ConfigurationTypeDropdown";
import { clientConfigTypes } from "features/clients/clientConfigsSetup";

const useStyles = CheckInConfigStyle;
const useConfigStyles = configStyles;

const CheckInConfig = ({ contract, open, clientConfig, handleClose }) => {
    const configClasses = useConfigStyles();
    const classes = useStyles();

    const [checkinSla, setCheckinSla] = useState([]);
    const [updateCheckIn] = useUpdateCheckInMutation();
    const [isSaving, setIsSaving] = useState(false);
    const { selectedVehicleTypes } = useSelector(state => state.mobileWorkflowState);
    let { data: defaultCheckinStatus, error: statusIdError, isLoading: isLoadingStatusId, refetch } = useGetDefaultStatusByClientIdQuery({ clientId: clientConfig?.id });

    //Client config params
    const [configFields, setConfigFields] = useState({});

    const onClose = () => {
        setCheckinSla(null);
        handleClose();
    }

    const handleSave = async () => {
        setIsSaving(true);

        let objectToSave = {
            clientId: clientConfig?.id,
            checkInSla: checkinSla?.filter(row => row.changed).map(row => ({ ...row, clientId: clientConfig?.id })) ?? [],
            selectedVehicleTypes: selectedVehicleTypes,
            ...configFields
        };
        let res = await updateCheckIn(objectToSave);

        setIsSaving(false);
        handleClose();

        if (!res?.error) {
            NotificationProvider.success('Saved successfully');
            onClose();
        } else {
            NotificationProvider.error('Failed to save');
        }
    }

    useEffect(() => {
        if (defaultCheckinStatus) {
            setConfigFields({ ...configFields, ['defaultCheckinStatus']: defaultCheckinStatus })
        }
    }, [defaultCheckinStatus])

    useEffect(() => {
        if (clientConfig) {
            const conditionalReportConfigParams = clientConfig?.config?.conditionalReportConfig;
            setConfigFields({ ...configFields, ...conditionalReportConfigParams })
        }
    }, [clientConfig])

    const onChangeConfigField = (field, value) => {
        setConfigFields({ ...configFields, [field]: value });
    }

    const tabs = [
        {
            label: 'General Data',
            component: <Grid container direction="column" spacing={1}>
                <ConfigurationTypeDropdown clientConfig={clientConfig} configType={clientConfigTypes.ConditionalReport} />
                <Paper className={classes.configFields}>
                    <ConditionReportConfig
                        defaultCheckinStatus={configFields.defaultCheckinStatus}
                        useConditionalReport={configFields.useConditionalReport}
                        useSinglePageCheckIn={configFields.useSinglePageCheckIn}
                        priceByReport={configFields.priceByReport}
                        onChangeField={onChangeConfigField}
                        clientConfig={clientConfig}
                    />
                </Paper>
                {
                    (configFields.useSinglePageCheckIn && configFields.useSinglePageCheckIn == true)
                    ?  <CheckInGeneralDataSinglePage
                        open={open}
                        clientConfig={clientConfig}
                        handleClose={handleClose}
                        checkinSla={checkinSla}
                        setCheckinSla={setCheckinSla}
                        />
                    : (!configFields?.useCustomConditionReport) ? <CheckInGeneralData
                        open={open}
                        clientConfig={clientConfig}
                        handleClose={handleClose}
                        checkinSla={checkinSla}
                        setCheckinSla={setCheckinSla}
                        /> : null
                }
            </Grid>
        },
        {
            label: 'Asset Types',
            component: <ClientVehicleTypes clientId={clientConfig?.id} />
        }
    ]

    return (
        <>
            {
                <Dialog
                    open={open}
                    onClose={onClose}
                    fullWidth
                    maxWidth='xl'
                >
                    <DialogTitle>
                        <Grid container justifyContent='space-between'>
                            <div>{`${contract?.label} SLA`}</div>
                            <div className={classes.btnWrapper}>
                                <Authorize profile={permissionProfiles.CLIENT_CONFIG.CHECKIN_SLA_ACTIONS}>
                                    <Button
                                        onClick={handleSave}
                                        disabled={isSaving}
                                        className={classes.button}
                                        variant="outlined"
                                        color="primary"
                                    >
                                        {isSaving ? "Saving..." : "Save"}
                                    </Button>
                                </Authorize>
                            </div>
                            {contract?.headerComponent}
                        </Grid>
                    </DialogTitle>
                    <DialogContent>
                        <TabsWrapper tabs={tabs} />
                    </DialogContent>
                </Dialog>

            }
        </>
    )
}
export default CheckInConfig;
