import { Grid, LinearProgress } from '@material-ui/core';
import Typography from '@mui/material/Typography';
import { questionTypes } from "features/Forms/formBuilder/formBuilderConsts";
import { theme } from "styles/theme";

const CustomFormProgressBar = ({ formResponse }) => {

    var numOfQuestion = formResponse?.form?.sections?.flatMap(section => section.questions)?.filter(q => q.questionType != questionTypes.Signature)?.length;
    var numOfAnswers = formResponse?.answers?.filter(a => a.questionType != questionTypes.Signature)?.length;
    var progress = numOfAnswers > 0 ? (numOfAnswers / numOfQuestion) * 100 : 0;

    return (
        <Grid container spacing={1} alignItems="center" justifyContent="space-between" style={{ padding: "0 0.7rem 0 0.5rem" }}>
            <Grid item style={{ flex: 1, paddingRight: '1rem' }}>
                <div style={{ width: '100%' }}>
                    <LinearProgress
                        style={{ height: 6, borderRadius: 3 }}
                        color="primary"
                        variant='determinate'
                        size="lg"
                        value={progress}
                    />
                </div>
            </Grid>

            <Grid item>
                <Typography
                    variant="body2"
                    sx={{ color: theme.palette.grey[600] }}
                >
                    {numOfAnswers + " / " + numOfQuestion}
                </Typography>
            </Grid>
        </Grid>
    );
}

export default CustomFormProgressBar;