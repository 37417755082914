import { apiTags } from 'app/store/apiTags';
import { baseQuery } from "../../../../../../app/baseQuery";
import { baseApi } from 'app/baseApi';

export const oneOnOneApi = baseApi.injectEndpoints({
    reducerPath: 'oneOnOneApi',
    baseQuery: baseQuery,
    endpoints: (builder) => ({
        getAllWorkers: builder.query({
            query: () => `worker`,
        }),
        getAllWorkersWithDeleted: builder.query({
            query: () => `worker/deleted`,
        }),
        getWorkersByLot: builder.query({
            query: () => `worker/lot`,
        }),
        getOneOnOnes: builder.query({
            query: () => `oneonone`,
            providesTags: [apiTags.ONE_ON_ONE]
        }),
        addOneOnOne: builder.mutation({
            query: meeting => ({
                url: `oneonone`,
                method: "POST",
                body: meeting
            }),
            invalidatesTags: [apiTags.ONE_ON_ONE]
        }),
        editOneOnOne: builder.mutation({
            query: meeting => ({
                url: `oneonone`,
                method: "PUT",
                body: meeting
            }),
            invalidatesTags: [apiTags.ONE_ON_ONE]
        }),
        deleteOneOnOne: builder.mutation({
            query: body => ({
                url: `oneonone`,
                method: "DELETE",
                body
            }),
            invalidatesTags: [apiTags.ONE_ON_ONE]
        }),
    }),
});

export const {
    useGetAllWorkersQuery,
    useGetAllWorkersWithDeletedQuery,
    useGetWorkersByLotQuery,
    useGetOneOnOnesQuery,
    useAddOneOnOneMutation,
    useEditOneOnOneMutation,
    useDeleteOneOnOneMutation
} = oneOnOneApi;

