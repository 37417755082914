import { Button, Grid } from '@material-ui/core';
import BasicModal from 'components/BasicModal.js';
import DataListHeader from "components/DataListHeader";
import EmptyPlaceholder from "components/EmptyPlaceholder/EmptyPlaceholder";
import PageComponent from "components/Page/PageComponent";
import SelectFieldsPopup from 'components/SelectFieldsPopup/selectFieldsPopup.js';
import { useGetAllActivityItemsQuery } from 'features/activity/activitySlice.js';
import { useGetDeletedLotsQuery, useGetLotsQuery } from "features/lots/lotSlice";
import { useState } from "react";
import { useSelector } from "react-redux";
import { InvoiceTypes } from '../../../invoiceConsts.ts';
import InvoiceScreenSelector from '../../../shared/InvoiceScreenSelector';
import { ActivityInvoiceStatus, activityDetailsColumns } from '../../activityInvoiceConsts.js';
import {
    useApproveActivityInvoiceMutation,
    useGetActivityInvoicesQuery,
    useGetActivityReportsMutation,
    useReprocessActivityInvoiceMutation
} from '../../activityInvoiceSlice.js';
import ActivityInvoiceHeader from '../ActivityInvoiceHeader/ActivityInvoiceHeader.js';
import ActivityInvoiceSummary from '../ActivityInvoiceSummary/ActivityInvoiceSummary.js';
import ActivityInvoice from '../ActivityInvoive/ActivityInvoice.js';
import ExportReportsButton from '../ExportReportsButton/ExportReportsButton.js';
import { groupBy, sumBy } from 'lodash';
import NotificationProvider from 'components/NotificationProvider.js';
import ActivityInvoiceStatusDisplay from './ActivityInvoiceStatus/ActivityInvoiceStatusDisplay.js';
import { activityInvoiceStyles } from '../ActivityInvoive/style.js';
import usePrompt from 'hooks/usePrompt.js';
import { permissionProfiles } from 'components/Authorize/permissionProfiles.js';
import Authorize from 'components/Authorize/Authorize.js';
import { arrayToObject } from 'utils/object-util.js';

const ActivityInvoicePage = () => {
    const classes = activityInvoiceStyles();

    const { triggerPrompt } = usePrompt();

    const { filters, searchVal } = useSelector(state => state.activityInvoiceState);

    let { data: invoices, error, isFetching, refetch: refetchActivityInvoices } = useGetActivityInvoicesQuery({
        clientId: filters?.['clientId'],
        month: filters?.['month'],
        year: filters?.['year'],
    }, { skip: !filters?.['clientId'] || !filters?.['month'] });

    // const prevDate = {
    //     month: filters?.['month'] == 1 ? 12 : filters?.['month'] - 1,
    //     year: filters?.['month'] == 1 ? filters?.['year'] - 1 : filters?.['year']
    // }

    // let { data: prevInvoices, error: prevInvoicesError, isFetching: isFetchingPrevInvoices, refetch: refetchPrevInvoices } = useGetActivityInvoicesQuery({
    //     clientId: filters?.['clientId'],
    //     month: prevDate?.['month'],
    //     year: prevDate?.['year'],
    // }, { skip: !filters?.['clientId'] || !filters?.['month'] });

    let { data: lots, error: lotError, isLoading: isLoadingLots } = useGetLotsQuery();
    let { data: deletedLots, error: deletedLotsotError, isLoading: isLoadingDeletedLots, } = useGetDeletedLotsQuery();
    let {
        data: activityItems,
        error: activityItemsError,
        isLoading: isLoadingActivityItems
    } = useGetAllActivityItemsQuery();
    const [getActivityReports, { isLoading: gettingReports }] = useGetActivityReportsMutation();
    const [reprocessActivityReport, { isLoading: reprocessActivity }] = useReprocessActivityInvoiceMutation();
    const [approveActivityInvoice, { isLoading: isApproving }] = useApproveActivityInvoiceMutation();

    const activityItemsLookup = arrayToObject(activityItems);

    let invoicesData = invoices?.map(invoice => {
        let lot = lots?.find(l => l.id === invoice?.lotId);
        if (!lot) {
            lot = deletedLots?.find(l => l?.id === invoice?.lotId)
        }
        let activityInvoiceDetailsWithNames = invoice?.activityInvoiceDetails?.map(activity => {
            return ({
                ...activity,
                activityItemName: `${activityItemsLookup?.[activity.activityItemId]?.name}${activity.description ? ` - ${activity.description}` : ''}`
            })
        })
        let activityInvoiceDetailsLookup = groupBy(activityInvoiceDetailsWithNames, 'activityItemName');
        return ({
            ...invoice,
            lot: lot,
            lotInfo: lot?.deletedDate ? `${lot?.name} (Deleted)` : [lot?.name, lot?.addressLine1, lot?.city, lot?.state, lot?.zipCode]?.join(', '),
            // we show only 1 line for each activityItemId and sum the prices, for other activities we show all activities
            activityInvoiceDetailsGrouped: Object.keys(activityInvoiceDetailsLookup)?.map(key => {
                const activityInvoices = activityInvoiceDetailsLookup[key];
                const activityInvoiceGrouped = {
                    ...activityInvoices[0],
                    totalCharge: sumBy(activityInvoices, item => item.totalCharge),
                    totalInternalCost: sumBy(activityInvoices, item => item.totalInternalCost),
                    totalRevenue: sumBy(activityInvoices, item => item.totalRevenue),
                    totalRevenueWithTax: sumBy(activityInvoices, item => item.totalRevenueWithTax)
                }
                return ({ ...activityInvoiceGrouped, activityItemName: `${activityInvoices[0]?.activityItemName} (${activityInvoices.length})`,  lotId: lot?.id })
            })
        })
    })

    invoicesData = invoicesData?.filter(inv => inv.lot != null);

    const dataForExport = invoicesData?.reduce((result, obj) => {
        return result.concat(obj?.activityInvoiceDetails);
    }, []);

    const mapExportData = row => {
        const detailsInfo = {};
        activityDetailsColumns?.map(col => {
            detailsInfo[col.key] = row?.[col.key]
        });
        const invoiceData = {
            ['Client']: filters?.['clientId'],
            ['Lot ID']: row.lotId,
            ...detailsInfo
        };

        return invoiceData;
    };

    let reportColumns = [
        { name: "Invoice Report", key: "1", selectedByDefault: true, width: 200 },
        { name: "Activity VIN Report", key: "2", selectedByDefault: true, width: 200 }
    ];

    const [selectedColumns, setSelectedColumns] = useState([...reportColumns].filter(c => c.selectedByDefault));

    const [openSelectFieldsPopup, setOpenSelectFieldsPopup] = useState(false);
    let [isDownloadingReports, setIsDownloadingReports] = useState(false);

    const isChecked = (checked, columnName) => {
        return [...checked].filter(column => column ? column.name == columnName : false).length > 0;
    }

    const onSelectExistingClick = () => {
        setOpenSelectFieldsPopup(true);
        setIsDownloadingReports(true);
    }

    const onCloseModal = () => {
        setOpenSelectFieldsPopup(false);
        setIsDownloadingReports(false);
    }

    const onSubmitSelectedFields = async (checked) => {
        setSelectedColumns(
            reportColumns.map((column) => (isChecked(checked, column.name) ? column : null))
                .filter(column => column)
        );

        let reportsToSend = [];
        reportColumns.map((column) => (isChecked(checked, column.name) ? column : null)).forEach((id) => {
            if (id !== null) {
                reportsToSend.push(parseInt(id.key));
            }
        })

        let reportsToSendObj = {
            ReportTypes: reportsToSend,
            ClientId: parseInt(filters?.['clientId']),
            Month: parseInt(filters?.['month']),
            Year: parseInt(filters?.['year']),
        }

        setOpenSelectFieldsPopup(false);

        setIsDownloadingReports(true);
        await getActivityReports(reportsToSendObj);
        setIsDownloadingReports(false);
    }
    const onRunProcess = async () => {
        let body = {
            clientId: parseInt(filters?.['clientId']),
            month: parseInt(filters?.['month']),
            year: parseInt(filters?.['year']),
        };
        await reprocessActivityReport(body);
        refetchActivityInvoices();
    }

    const onApproveInvoice = async () => {
        triggerPrompt({
            title: "Approve Invoice",
            content: <div>
                The approved invoice will be sent to QuickBooks in a few hours.
                <br />Are you sure you want to continue?
            </div>,
            onConfirm: async () => {
                let body = {
                    ids: invoicesData.map(inv => inv.id)
                };
                let res = await approveActivityInvoice(body);
                if (!res?.error) {
                    NotificationProvider.success('Invoice approved successfully');
                } else {
                    NotificationProvider.error('Failed to approve invoice');
                }
            },
            onCancel: async () => {
            },
        });

    }

    const needsFiltering = !filters?.['clientId'] || !filters?.['month'];

    const getStatus = (invoicesData) => {
        if (invoicesData?.length && invoicesData?.every(inv => inv.status === ActivityInvoiceStatus.APPROVED))
            return ActivityInvoiceStatus.APPROVED;
        if (invoicesData?.length && invoicesData.find(inv => inv.status === ActivityInvoiceStatus.SENT_TO_QB) && invoicesData?.every(inv => inv.status === ActivityInvoiceStatus.SENT_TO_QB || inv.activityInvoiceDetails?.length == 0))
            return ActivityInvoiceStatus.SENT_TO_QB;
        if (invoicesData?.length && invoicesData.find(inv => inv.status === ActivityInvoiceStatus.SENT_TO_QB || inv.status === ActivityInvoiceStatus.FAILED) && invoicesData?.every(inv => inv.status === ActivityInvoiceStatus.SENT_TO_QB || inv.status === ActivityInvoiceStatus.FAILED || inv.activityInvoiceDetails?.length == 0))
            return ActivityInvoiceStatus.FAILED;
        return ActivityInvoiceStatus.PENDING
    }

    const invoiceStatus = getStatus(invoicesData);
    // const prevInvoiceStatus = getStatus(prevInvoices);

    // We allow to generate invoices only for the previous month
    // If the previous month's invoice was already sent to QB then we allow to generate invoices for the current month
    // const canGenerateInvoice = () => {
    //     let today = new Date();
    //     let previousMonth = new Date()
    //     previousMonth.setDate(0)
    //     if (filters?.['year'] == previousMonth.getFullYear() &&
    //         filters?.['month'] == (previousMonth.getMonth() + 1) &&
    //         invoiceStatus != ActivityInvoiceStatus.SENT_TO_QB
    //     ) {
    //         return true;
    //     }
    //     if (filters?.['year'] == today.getFullYear() &&
    //         filters?.['month'] == (today.getMonth() + 1) &&
    //         prevInvoiceStatus == ActivityInvoiceStatus.SENT_TO_QB &&
    //         invoiceStatus != ActivityInvoiceStatus.SENT_TO_QB
    //     ) {
    //         return true;
    //     }
    //     return false;
    // };

    return (
        <PageComponent
            loading={isFetching}
            header={
                <DataListHeader
                    titleSize={6}
                    titleComponent={(
                        <InvoiceScreenSelector
                            selected={InvoiceTypes.ACTIVITY}
                        />
                    )}
                    actionButtons={<Grid container alignItems='center' spacing={1}>
                        {!needsFiltering && (
                            <>
                                <ActivityInvoiceStatusDisplay status={invoiceStatus} />
                                {invoiceStatus === ActivityInvoiceStatus.PENDING &&
                                    <>
                                        <Authorize profile={permissionProfiles.INVOICE.APPROVE_DELETE_MECHANICAL_INVOICE}>
                                            <Grid item>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    disabled={isApproving}
                                                    onClick={onApproveInvoice}>
                                                    Approve Activity Invoice
                                                </Button>
                                            </Grid>
                                        </Authorize>
                                        {/* {canGenerateInvoice() && */}
                                        <Grid item>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                disabled={reprocessActivity}
                                                onClick={onRunProcess}>
                                                Generate Activity Invoice
                                            </Button>
                                        </Grid>
                                        {/* } */}
                                    </>
                                }
                                <ExportReportsButton />
                            </>
                        )}
                    </Grid>
                    }
                />
            }>
            <ActivityInvoiceHeader />
            <BasicModal
                open={openSelectFieldsPopup}
                header={`Select Reports`}
                handleClose={() => onCloseModal(false)}
                component={<SelectFieldsPopup columns={reportColumns}
                    selectedColumns={selectedColumns}
                    isChecked={isChecked}
                    onSubmit={onSubmitSelectedFields}
                    handleClose={() => onCloseModal(false)}
                />}
            />
            {
                needsFiltering ?
                    <EmptyPlaceholder text={'Please select client and month'} /> :
                    !!invoicesData ?
                        <>

                            <Grid direction='column' spacing={1}>
                                {
                                    invoicesData?.map(invoice =>
                                        <Grid item>
                                            <ActivityInvoice key={invoice.id} invoice={invoice} />
                                        </Grid>)
                                }
                            </Grid>
                            <ActivityInvoiceSummary invoices={invoicesData} />
                        </> :
                        <EmptyPlaceholder text={'No Invoices'} />
            }
        </PageComponent>
    )
}
export default ActivityInvoicePage;