import { makeStyles } from "@material-ui/core";

export const vehiclePendingSyncCheckInsStyle = makeStyles((theme) => ({
    shakeIcon: {
        animation: '$shake 1s ease-in-out infinite'
    },
    "@keyframes shake": {
        "0%": {
            transform: 'translateX(0)'
        },
        "25%": {
            transform: 'translateX(-5px)'
        },
        "50%": {
            transform: 'translateX(5px)'
        },
        "75%": {
            transform: 'translateX(-5px)'
        },
        "100% ": {
            transform: 'translateX(5px)'
        }

    }
}));