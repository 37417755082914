import { makeStyles } from "@material-ui/core";

export const workorderDashboardPageStyles = makeStyles((theme) => ({
    card: {
        padding: '1em 2em',
        height: '100%',
        boxShadow: '2px 3px 10px #d7d6d6',
        position: 'relative',
        overflowY: 'auto'
    },
    graphHeader: {
        color: theme.palette.primary.dark,
        marginTop: '0',
        fontWeight: 500
    },
    graphWrapper: {
        height: '100%',
        minHeight: '20rem'
    },
    subTitle: {
        color: theme.palette.primary.dark,
        fontSize: 20,
        fontWeight: 500,
        marginTop: 0,
        marginBottom: 0
    },
    wrapper: {
        height: '100%',
        cursor: 'pointer'
    },
    count: {
        color: theme.palette.primary.main,
        fontSize: '3em',
        fontWeight: 700
    },
    text: {
        color: theme.palette.primary.dark,
        fontWeight: 500,
        fontSize: '1.1em',
        marginTop: 0
    },
    tooltip: {
        background: '#fff',
        padding: '1em',
        boxShadow: '1px 2px 7px #efefef'
    },
    cardWrapper: {
        position: 'relative'
    },
    actions: {
        position: 'absolute',
        right: '-1em',
        top: '-1em'
    },
    icon: {
        paddingRight: '0.5rem'
    },
    dialogTable: {
        height: '60vh'
    },
    table: {
        height: '75vh'
    },
    titleContainer: {
        display: 'flex',
    },
    titleWrapper: {
        display: 'flex',
        alignItems: 'center',
    },
    paper: {
        minHeight: '50vh'
    },
    activityCountWrapper: {
        width: '100%'
    },
    activityCount: {
        background: 'linear-gradient(90deg, rgba(170, 218, 255, 1) 0%, rgba(170, 218, 255, 1)50%,rgba(170, 218, 255, 0.3) 100%);',
        textAlign: 'center',
        borderRight: '1px solid rgba(0, 144, 254, 1)',
        padding: '5px 7px',
        color: 'white',
        fontWeight: 'bold'
    },
    exportButton: {

    },
    totalCost: {
        fontSize: '5em',
        color: theme.palette.primary.main
    },
    typeIcon: {
        '& svg': {
            //            height: '1em'
            width: '1.5em'
        }
    },
    filtersWrapper: {
        display: 'flex',
        alignItems: 'center'
    },
    filterItem: {
        marginRight: '0.5rem'
    },
    buttonsWrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end'
    }
}));