import { Grid } from "@material-ui/core";
import GraphHeader from "features/landing/components/WorkorderDashboard/GraphHeader/GraphHeader";
import { useEffect, useMemo, useState } from 'react';
import { Bar, BarChart, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

const UsersPerClient = ({ activeUsers, inActiveUsers, clients }) => {
    activeUsers = activeUsers || [];
    inActiveUsers = inActiveUsers || [];

    const [chartValue, setChartValue] = useState([]);
    
    const chartData = useMemo(() => {
        if (!clients || (!activeUsers && !inActiveUsers)) return [];
        const activeUsersArray = activeUsers.filter(user => !!user?.accessClient?.length);
        const inactiveUsersArray = inActiveUsers.filter(user => !!user?.accessClient?.length);

        return clients.map(client => {
            let activeCount = 0, inactiveCount = 0;
            activeUsersArray.forEach(user => {
                const usersFound = user.accessClient.filter(x => x.id === client.id);
                if (usersFound.length > 0) {
                    activeCount += 1;
                }
            });
            inactiveUsersArray.forEach(user => {
                const usersFound = user.accessClient.filter(x => x.id === client.id);
                if (usersFound.length > 0) {
                    inactiveCount += 1;
                }
            });
            return {
                ...client,
                activeQuantity: activeCount,
                inactiveQuantity: inactiveCount
            };
        });
    }, [clients, activeUsers, inActiveUsers]);

    useEffect(() => {
        if (chartData) {
            setChartValue(chartData);
        }
    }, [chartData]);

    return (
        <Grid style={{ height: 350 }}>
            <Grid item>
                <GraphHeader
                    title={'Users Per Client'}
                />
            </Grid>
            <Grid item style={{ height: 300 }}>
                <ResponsiveContainer width="90%" height="100%">
                    <BarChart
                        width={800}
                        height={350}
                        data={chartValue}
                        layout='horizontal'
                        barSize={15}
                        cx={"50%"}
                        cy={"50%"}
                        innerRadius={70}
                        outerRadius={90}
                    >
                        <YAxis />
                        <XAxis dataKey="name" />
                        <Tooltip />
                        <Legend />
                        <Bar dataKey="activeQuantity" fill="#9bdc8a" name="Active Users" />
                        <Bar dataKey="inactiveQuantity" fill="#e8a48e" name="Inactive Users" />
                    </BarChart>
                </ResponsiveContainer>
            </Grid>
        </Grid>
    );
};

export default UsersPerClient;
