import { AutoSizer } from "react-virtualized";
import { FixedSizeList } from "react-window";
import EmptyPlaceholder from "components/EmptyPlaceholder/EmptyPlaceholder";
import WorkOrderCard from "./WorkOrderCard";

const WorkorderList = ({ activities, showActions = true }) => {

    const Row = ({ index, style }) => (
        <div style={style}>
            <WorkOrderCard
                workOrder={activities?.[index]}
                handleDone={() => { }}
                key={index}
                showActions={showActions}
            />
        </div>
    );

    return <>
        {!!activities?.length ?
            <div style={{ flex: 1, marginRight: '1rem' }}>
                <AutoSizer>
                    {({ height, width }) => (
                        <FixedSizeList
                            className="List"
                            height={height + 190}
                            itemCount={activities?.length}
                            itemSize={130}
                            width={width}
                        >
                            {Row}
                        </FixedSizeList>
                    )}
                </AutoSizer>
            </div>
            : <EmptyPlaceholder text='There are no activities matching this criteria' />
        }
    </>
}
export default WorkorderList;