import { makeStyles } from "@material-ui/core";

export const fleetManagementDashboardStyle = makeStyles((theme) => ({
    widgetWrapper: {
        boxShadow: '2px 3px 10px #d7d6d6',
        paddingBottom: '0.5rem',
        height: '100%'
    },
    widgetWrapperBig: {
        height: '100%',
        paddingBottom: '1rem'
    }
}));