import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { rolesApi } from 'components/Authorize/rolesSlice';
import genericTableStateSlice from 'components/DataTable/genericTableStateSlice';
import { userPreferencesApi } from 'components/UserPreferences/userPreferencesSlice';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import { customReportApi } from 'features/Forms/CustomReport/customReportSlice';
import formStateReducer from 'features/Forms/FormList/formStateSlice';
import { formBuilderApi } from 'features/Forms/formBuilder/formBuilderSlice';
import issuesTrackerFiltersReducer from 'features/IssuesTracker/issuesTrackerFiltersSlice';
import { issuesTrackerApi } from "features/IssuesTracker/issuesTrackerSlice";
import { mobileActivityApi, mobileActivitySlice } from 'features/MobileActivity/mobileActivitySlice';
import { userTaskApi } from 'features/Tasks/components/UserTasks/userTaskSlice';
import { taskApi, taskStateSlice } from 'features/Tasks/taskSlice';
import { cameraEventsApi } from 'features/access/CamerEvents/cameraEventSlice';
import cameraEventsReducer from 'features/access/CamerEvents/cameraEventsStateSlice';
import { doorReleaseApi } from 'features/access/DoorRelease/doorReleaseSlice';
import doorReleaseReducer from 'features/access/DoorRelease/doorReleaseStateSlice';
import { accessApi } from "features/access/accessSlice";
import accessStateReducer from 'features/access/accessStateSlice';
import { activityTypeInventoryApi } from 'features/activity/ActivityTypeInventorySlice';
import { activityTypeStepApi } from 'features/activity/ActivityTypeStepSlice';
import activityReportFiltersReducer from 'features/activity/activityReportFiltersSlice';
import { activityApi } from "features/activity/activitySlice";
import addAssetReducer from 'features/addAsset/addAssetSlice';
import { clientAccountApi } from 'features/clients/clientAccountSlice';
import { clientConfigApi } from 'features/clients/clientConfigSlice';
import { lotConfigApi, lotConfigSlice } from 'features/clients/clientConfigs/lots/lotConfigSlice';
import { washSlaSlice } from 'features/clients/clientConfigs/washes/WashSlaSlice';
import { clientApi } from "features/clients/clientSlice";
import { mobileWorkflowSlice, workflowApi } from 'features/clients/contracts/EditContractPopup/EditWorkFlowPopup/workFlowSlice';
import { mechanicalsApi } from 'features/clients/contracts/MechanicalWorkConfig/mechanicalsSlice';
import { driverApi } from 'features/drivers/driversSlice';
import { flagsApi } from 'features/flag/flagsSlice';
import inventoryFiltersReducer from 'features/inventory/inventoryFiltersSlice';
import { activityInvoiceStateSlice } from 'features/invoice/activity/activityInvoiceSlice';
import { loadInvoiceApi, loadInvoiceStateSlice } from 'features/invoice/loads/loadInvoiceSlice';
import { reportsApi } from 'features/invoice/reports/ReportsSlice';
import reportsFilterReducer from 'features/invoice/reports/reportsPage/reportsFilterSlice';
import { storageInvoiceApi, storageInvoiceStateSlice } from 'features/invoice/storage/storageInvoiceSlice.ts';
import { billingApi, billingDashboardSlice } from 'features/landing/components/BillingDashboard/billingSlice';
import { inspectionSlice } from 'features/landing/components/KpiDashboard/navigations/KpiInspection/inspectionSlice';
import OneOnOneFiltersReducer from 'features/landing/components/KpiDashboard/navigations/KpiOneOnOne/OneOnOneFilterSlice';
import { oneOnOneApi } from 'features/landing/components/KpiDashboard/navigations/KpiOneOnOne/OneOnOneSlice';
import assetsFiltersSlice from 'features/landing/components/WorkorderDashboard/CreateWorkOrder/Assets/assetsFiltersSlice.js';
import { workorderDashboardSlice } from 'features/landing/components/WorkorderDashboard/workorderDashboardSlice';
import { dashboardSlice } from 'features/landing/dashboardSlice';
import loadGroupViewReducer from 'features/loads/loadGroupViewSlice';
import loadFiltersReducer from 'features/loads/loadsFiltersSlice';
import { loadApi } from 'features/loads/loadsSlice';
import loadStateReducer from 'features/loads/loadsStateSlice';
import { submarketApi } from 'features/lots/components/LotSubMarketsPage/lotSubMarketsSlice';
import { lotApi, subMarketsSlice } from 'features/lots/lotSlice';
import { meApi } from 'features/me/meSlice';
import { mechanicalTaskSlice } from 'features/mechanicalTasks/slice';
import { notesApi } from 'features/notes/notesSlice';
import activityTypeFiltersSlice from 'features/pricing/ActivityTypesList/activityTypeFiltersSlice';
import activityPricingFiltersSlice from 'features/pricing/PricingList/activityPricingFiltersSlice';
import { activityTypePriceApi } from 'features/pricing/pricingSlice';
import { serviceProviderApi } from 'features/serviceProvider/serviceProviderSlice';
import { subscriptionApi } from 'features/subscriptions/subscriptionSlice';
import { transporterApi } from "features/transporter/transporterSlice";
import seamlessTokenReducer from 'features/user/seamlessTokenSlice';
import userReducer from 'features/user/userSlice';
import { userTaskConfigApi } from 'features/users/components/editUser/UserTasks/userTaskConfigSlice';
import usersFiltersReducer from 'features/users/usersFiltersSlice';
import { usersApi } from 'features/users/usersSlice';
import { reportApi } from "features/vehicles/Reports/reportSlice";
import auditFiltersSlice from 'features/vehicles/VehicleLotAudit/auditFiltersSlice';
import auditListFiltersSlice from 'features/vehicles/VehicleLotAudit/components/AuditList/auditListFiltersSlice';
import { vehicleStatusApi } from 'features/vehicles/VehicleStatus/vehicleStatusSlice';
import { configurationApi } from "features/vehicles/configurationSlice";
import { readinessApi } from 'features/vehicles/readiness/readinessSlice';
import vehicleFiltersReducer from 'features/vehicles/vehicleFiltersSlice';
import usersTableFiltersReducer from 'features/users/UserManagement/UsersTable/usersTableFiltersSlice';
import { vehicleApi } from "features/vehicles/vehicleSlice";
import { vendorApi } from "features/vendors/vendorSlice";
import { createBrowserHistory } from "history";
import { loadUser, reducer as oidcReducer } from 'redux-oidc';
import { confirmationApi } from '../../components/ExternalConfirmation/confirmationSlice';
import { contactTypeApi } from "../../features/contactType/contactTypeSlice";
import { securityPhotosApi } from "../../features/lots/components/SecurityPhotos/SecurityPhotosSlice";
import { rtkQueryErrorLogger } from '../errorHandling';
import userManager from "../userManager";
import { userManagementApi } from "features/users/UserManagement/userManagementSlice";
import { permissionApi } from "features/users/UserManagement/permissionSlice";
import { roleApi } from "../../features/users/UserManagement/roleSlice";
import taxRatesFiltersReducer from 'features/taxRate/taxRateFilterSlice';
import { taxRatesApi } from 'features/taxRate/TaxRateSlice';
import { reservationApi } from 'features/Reservation/reservationSlice';
import reservationsFiltersSlice from 'features/Reservation/reservationsFiltersSlice';
import { vendorFiltersSlice } from 'features/vendors/vendorFiltersSlice';
import { vehicleAttachmentsApi } from 'features/vehicles/vehicleAttachmentsSlice';
import { quickBooksAccountMappingApi, quickBooksMappingFiltersSlice } from 'features/invoice/shared/QuickBooksAccountMapping/QuickBooksAccountMappingSlice';
import { fleetManagementDashboardSlice } from 'features/landing/components/FleetManagementDashboard/fleetManagementDashboardSlice';
import { lotsCapacityFiltersSlice } from 'features/lots/components/LotsPage/LotsCapacity/LotsCapacityFiltersSlice';

export const history = createBrowserHistory();

let store = configureStore({
    reducer: {
        oidc: oidcReducer,
        user: userReducer,
        seamlessToken: seamlessTokenReducer,
        usersFilters: usersFiltersReducer,
        vehicleFilters: vehicleFiltersReducer,
        usersTableFilters: usersTableFiltersReducer,
        oneOnOneFilters: OneOnOneFiltersReducer,
        loadFilters: loadFiltersReducer,
        loadState: loadStateReducer,
        loadGroupViewState: loadGroupViewReducer,
        accessState: accessStateReducer,
        formState: formStateReducer,
        doorReleaseState: doorReleaseReducer,
        cameraEventsState: cameraEventsReducer,
        auditFilters: auditFiltersSlice,
        auditListFilters: auditListFiltersSlice,
        activityReportFilters: activityReportFiltersReducer,
        activityTypeFilters: activityTypeFiltersSlice,
        activityPricingFilters: activityPricingFiltersSlice,
        assetsFilters: assetsFiltersSlice,
        addAsset: addAssetReducer,
        taskState: taskStateSlice.reducer,
        subMarketsState: subMarketsSlice.reducer,
        dashboardState: dashboardSlice.reducer,
        inspectionState: inspectionSlice.reducer,
        loadInvoiceState: loadInvoiceStateSlice.reducer,
        storageInvoiceState: storageInvoiceStateSlice.reducer,
        activityInvoiceState: activityInvoiceStateSlice.reducer,
        mobileWorkflowState: mobileWorkflowSlice.reducer,
        lotConfigState: lotConfigSlice.reducer,
        billingDashboardState: billingDashboardSlice.reducer,
        workorderDashboardState: workorderDashboardSlice.reducer,
        fleetManagementDashboardState: fleetManagementDashboardSlice.reducer,
        mobileActivityState: mobileActivitySlice.reducer,
        washConfigState: washSlaSlice.reducer,
        mechanicalTaskSlice: mechanicalTaskSlice.reducer,
        configurationApiSlice: configurationApi.reducer,
        reportApiSlice: reportApi.reducer,
        inventoryFilters: inventoryFiltersReducer,
        issuesTrackerFilters: issuesTrackerFiltersReducer,
        reservationsFilters: reservationsFiltersSlice,
        genericTableState: genericTableStateSlice,
        reportsFilter: reportsFilterReducer,
        taxRatesFilters: taxRatesFiltersReducer,
        vendorFilters: vendorFiltersSlice.reducer,
        quickBooksMappingFilters: quickBooksMappingFiltersSlice.reducer,
        lotsCapacityFilters: lotsCapacityFiltersSlice.reducer,

        [userManagementApi.reducerPath]: userManagementApi.reducer,
        [permissionApi.reducerPath]: permissionApi.reducer,
        [vehicleApi.reducerPath]: vehicleApi.reducer,
        [configurationApi.reducerPath]: configurationApi.reducer,
        [lotApi.reducerPath]: lotApi.reducer,
        [loadApi.reducerPath]: loadApi.reducer,
        [clientApi.reducerPath]: clientApi.reducer,
        [clientConfigApi.reducerPath]: clientConfigApi.reducer,
        [lotConfigApi.reducerPath]: lotConfigApi.reducer,
        [accessApi.reducerPath]: accessApi.reducer,
        [doorReleaseApi.reducerPath]: doorReleaseApi.reducer,
        [cameraEventsApi.reducerPath]: cameraEventsApi.reducer,
        [vendorApi.reducerPath]: vendorApi.reducer,
        [driverApi.reducerPath]: driverApi.reducer,
        [activityApi.reducerPath]: activityApi.reducer,
        [transporterApi.reducerPath]: transporterApi.reducer,
        [subscriptionApi.reducerPath]: subscriptionApi.reducer,
        [confirmationApi.reducerPath]: loadApi.reducer,
        [meApi.reducerPath]: loadApi.reducer,
        [readinessApi.reducerPath]: readinessApi.reducer,
        [taskApi.reducerPath]: taskApi.reducer,
        [workflowApi.reducerPath]: workflowApi.reducer,
        [loadInvoiceApi.reducerPath]: loadInvoiceApi.reducer,
        [storageInvoiceApi.reducerPath]: loadInvoiceApi.reducer,
        [submarketApi.reducerPath]: submarketApi.reducer,
        [usersApi.reducerPath]: usersApi.reducer,
        [userTaskApi.reducerPath]: userTaskApi.reducer,
        [userTaskConfigApi.reducerPath]: userTaskConfigApi.reducer,
        [userPreferencesApi.reducerPath]: userPreferencesApi.reducer,
        [oneOnOneApi.reducerPath]: oneOnOneApi.reducer,
        [issuesTrackerApi.reducerPath]: issuesTrackerApi.reducer,
        [mechanicalsApi.reducerPath]: mechanicalsApi.reducer,
        [billingApi.reducerPath]: billingApi.reducer,
        [mobileActivityApi.reducerPath]: mobileActivityApi.reducer,
        [notesApi.reducerPath]: notesApi.reducer,
        [flagsApi.reducerPath]: flagsApi.reducer,
        [activityTypePriceApi.reducerPath]: activityTypePriceApi.reducer,
        [serviceProviderApi.reducerPath]: serviceProviderApi.reducer,
        [activityTypeInventoryApi.reducerPath]: activityTypeInventoryApi.reducer,
        [activityTypeStepApi.reducerPath]: activityTypeStepApi.reducer,
        [reportApi.reducerPath]: readinessApi.reducer,
        [securityPhotosApi.reducerPath]: securityPhotosApi.reducer,
        [formBuilderApi.reducerPath]: formBuilderApi.reducer,
        [customReportApi.reducerPath]: customReportApi.reducer,
        [rolesApi.reducerPath]: rolesApi.reducer,
        [roleApi.reducerPath]: roleApi.reducer,
        [clientAccountApi.reducerPath]: clientAccountApi.reducer,
        [contactTypeApi.reducerPath]: contactTypeApi.reducer,
        [vehicleStatusApi.reducerPath]: vehicleStatusApi.reducer,
        [reportsApi.reducerPath]: reportsApi.reducer,
        [taxRatesApi.reducerPath]: taxRatesApi.reducer,
        [reservationApi.reducerPath]: reservationApi.reducer,
        router: connectRouter(history),
        [vehicleAttachmentsApi.reducerPath]: vehicleAttachmentsApi.reducer,
        [quickBooksAccountMappingApi.reducerPath]: quickBooksAccountMappingApi.reducer,
    },
    middleware: getDefaultMiddleware({ serializableCheck: false })
        .concat(userManagementApi.middleware)
        .concat(permissionApi.middleware)
        .concat(roleApi.middleware)
        .concat(vehicleApi.middleware)
        .concat(configurationApi.middleware)
        .concat(lotApi.middleware)
        .concat(submarketApi.middleware)
        .concat(usersApi.middleware)
        .concat(userTaskApi.middleware)
        .concat(userTaskConfigApi.middleware)
        .concat(oneOnOneApi.middleware)
        .concat(clientApi.middleware)
        .concat(clientConfigApi.middleware)
        .concat(lotConfigApi.middleware)
        .concat(accessApi.middleware)
        .concat(doorReleaseApi.middleware)
        .concat(cameraEventsApi.middleware)
        .concat(vendorApi.middleware)
        .concat(driverApi.middleware)
        .concat(activityApi.middleware)
        .concat(transporterApi.middleware)
        .concat(loadApi.middleware)
        .concat(subscriptionApi.middleware)
        .concat(confirmationApi.middleware)
        .concat(readinessApi.middleware)
        .concat(taskApi.middleware)
        .concat(workflowApi.middleware)
        .concat(loadInvoiceApi.middleware)
        .concat(storageInvoiceApi.middleware)
        .concat(mechanicalsApi.middleware)
        .concat(billingApi.middleware)
        .concat(notesApi.middleware)
        .concat(flagsApi.middleware)
        .concat(activityTypeInventoryApi.middleware)
        .concat(activityTypeStepApi.middleware)
        .concat(serviceProviderApi.middleware)
        .concat(routerMiddleware(history))
        .concat(rtkQueryErrorLogger)
        .concat(rolesApi.middleware)
        .concat(securityPhotosApi.middleware)
        .concat(clientAccountApi.middleware)
        .concat(reportApi.middleware)
        .concat(contactTypeApi.middleware)
        .concat(vehicleStatusApi.middleware)
        .concat(reportsApi.middleware)
        .concat(taxRatesApi.middleware)
        .concat(reservationApi.middleware)
        .concat(vehicleAttachmentsApi.middleware)
        .concat(quickBooksAccountMappingApi.middleware)
});

loadUser(store, userManager)
    .then(r => console.log('user loaded', r));

export default store;
