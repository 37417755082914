import { apiTags } from 'app/store/apiTags';
import { baseApi } from '../../app/baseApi';
import { baseQuery } from "../../app/baseQuery";

const basePath = 'vehicle';
export const vehicleApi = baseApi.injectEndpoints({
    reducerPath: 'vehicleApi',
    baseQuery: baseQuery,
    tagTypes: ["Vehicles", "Vehicle"],
    endpoints: (builder) => ({
        getVehicles: builder.query({
            query: ({ includeHistory, clientId, clientAccountId }) => `vehicle?includeHistory=${includeHistory}${clientId ? `&clientId=${clientId}` : ''}${clientAccountId ? `&clientAccountId=${clientAccountId}` : ''}`,
            providesTags: [apiTags.VEHICLES, apiTags.FLAGS]
        }),
        searchVehicles: builder.query({
            query: ({
                includeHistory,
                page,
                limit,
                search,
                columnToSort,
                sortDirection,
                lotId,
                clientId,
                vehicleStatusId,
                vehicleTypeId,
                dateInStart,
                dateInEnd,
                dateOutStart,
                dateOutEnd,
                submarketId,
                domicileLot,
                cacheKey
            }) => `${basePath}/search?includeHistory=${includeHistory ?? ""}${page ? `&page=${page}` : ''}${limit ? `&limit=${limit}` : ''}&search=${search ?? ""}&columnToSort=${columnToSort ?? ""}&sortDirection=${sortDirection ?? ""}&lotId=${lotId ?? ""}&clientId=${clientId ?? ""}&vehicleTypeId=${vehicleTypeId ?? ""}&vehicleStatusId=${vehicleStatusId ?? ""}&dateInStart=${dateInStart ?? ""}&dateInEnd=${dateInEnd ?? ""}&dateOutStart=${dateOutStart ?? ""}&dateOutEnd=${dateOutEnd ?? ""}&submarketId=${submarketId ?? ""}&domicileLot=${domicileLot ?? ""}`,
            providesTags: [apiTags.VEHICLES]
        }),
        getVehiclesCount: builder.query({
            query: ({ includeHistory, cacheKey }) => `${basePath}/count?includeHistory=${includeHistory}&c=${cacheKey}`,
            providesTags: [apiTags.VEHICLES]
        }),
        getVehicleTypes: builder.query({
            query: (onlyShowInApp) => `${basePath}/type?onlyShowInApp=${onlyShowInApp ?? false}`,
            providesTags: [apiTags.VEHICLES_TYPES]
        }),
        getVehicleTypeCategory: builder.query({
            query: () => `${basePath}/type_category`,
            providesTags: [apiTags.VEHICLES_TYPES]
        }),
        getClientVehicleTypes: builder.query({
            query: (onlyShowInApp) => `${basePath}/typeForClient?onlyShowInApp=${onlyShowInApp ?? false}`,
            providesTags: [apiTags.VEHICLES_TYPES]
        }),
        getVehicleDetails: builder.query({
            query: (id) => `${basePath}/${id}`,
            providesTags: [apiTags.VEHICLE]
        }),
        getVehicleDetailsExtended: builder.query({
            query: (id) => `${basePath}/vehicledetailextended/${id}`,
            providesTags: [apiTags.VEHICLE]
        }),
        getActivityLog: builder.query({
            query: (id) => `${basePath}/activity/${id}`,
            providesTags: [apiTags.VEHICLE, apiTags.ACTIVITY_LOG]
        }),
        getImageTypes: builder.query({
            query: () => `imageType`,
        }),
        editAsset: builder.mutation({
            query: updatedAsset => ({
                url: `${basePath}/edit`,
                method: "POST",
                body: updatedAsset
            }),
            invalidatesTags: [apiTags.VEHICLES, apiTags.VEHICLE, apiTags.READINESS]
        }),
        addAsset: builder.mutation({
            query: updatedAsset => ({
                url: `${basePath}/add`,
                method: "POST",
                body: updatedAsset
            }),
            invalidatesTags: [apiTags.VEHICLES, apiTags.VEHICLE]
        }),
        updateVehicleDetail: builder.mutation({
            query: detail => ({
                url: `${basePath}/detail`,
                method: "PUT",
                body: detail
            }),
            invalidatesTags: [apiTags.VEHICLE]
        }),
        importActivityInspection: builder.mutation({
            query: form => ({
                url: `${basePath}/importsicompletion`,
                method: "POST",
                body: form
            }),
        }),
        deleteAsset: builder.mutation({
            query: id => ({
                url: `${basePath}/deleteAsset`,
                method: "POST",
                body: id
            }),
            invalidatesTags: [apiTags.VEHICLES]
        }),
        mergeAssets: builder.mutation({
            query: records => ({
                url: `${basePath}/merge`,
                method: "POST",
                body: {
                    records: records
                }
            }),
            invalidatesTags: [apiTags.VEHICLE]
        }),
        bulkUpdate: builder.mutation({
            query: body => ({
                url: `${basePath}/bulk`,
                method: "POST",
                body
            }),
            invalidatesTags: [apiTags.VEHICLE]
        }),
        deleteVehicleDetail: builder.mutation({
            query: id => ({
                url: `${basePath}/vehicledetail/delete`,
                method: "POST",
                body: { vehicleDetailId: id }
            }),
            invalidatesTags: [apiTags.VEHICLE]
        }),
        getFuelTypes: builder.query({
            query: () => `${basePath}/fuelType`,
        }),
        getExtendedFieldTypes: builder.query({
            query: () => `${basePath}/extendedFields`,
        }),
        findMatch: builder.query({
            query: (params) => `${basePath}/match?${Object.keys(params).filter(key => params[key]).map(key => `${key}=${params[key]}`).join('&')}`,
        }),
        getDecodedVin: builder.query({
            query: (vin) => `${basePath}/decode/${vin}`,
        }),
        importAssets: builder.mutation({
            query: assets => ({
                url: `${basePath}/import`,
                method: "POST",
                body: assets
            }),
        }),
        getImportAssetsTemplate: builder.mutation({
            query: body => ({
                url: `vehicle/downloadtemplate`,
                method: "POST",
                body: body,
                responseHandler: async (response) => {
                    const filename = 'Import assets template.csv';
                    const url = window.URL.createObjectURL(await response.blob());
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', filename);
                    link.click();
                },
                cache: "no-cache"
            })
        }),
        getVehicleLocationInfo: builder.query({
            query: (params) => {
                const queryString = Object.keys(params).map(param => params[param] ? `${param}=${params[param]}` : undefined).filter(k => k).join('&');
                return `${basePath}/location?${queryString}`
            },
        }),
        getOnLotStatuses: builder.query({
            query: () => `${basePath}/onLotStatuses`,
        }), 
        getCustomFormList: builder.query({
            query: ({vehicleId, formType}) => `${basePath}/customForm/list/?vehicleId=${vehicleId}&formType=${formType}`,
        }),
        getCustomFormOutput: builder.query({
            query: ({vehicleId, formType, includeImages}) => `${basePath}/customForm/?vehicleId=${vehicleId}&formType=${formType}&includeImages=${includeImages}`,
            providesTags: [apiTags.CUSTOM_REPORT]
        }),
        getDocumentTypes: builder.query({
            query: () => 'documentType'
        })
    }),
});

export const {
    useGetVehiclesQuery, useSearchVehiclesQuery, useLazySearchVehiclesQuery, useGetVehiclesCountQuery, useGetVehicleTypesQuery, useGetVehicleDetailsQuery, 
    useEditAssetMutation, useAddAssetMutation, useGetActivityLogQuery, useGetImageTypesQuery, useUpdateVehicleDetailMutation, 
    useImportActivityInspectionMutation, useDeleteAssetMutation, useDeleteVehicleDetailMutation, useBulkUpdateMutation, useMergeAssetsMutation,
    useGetFuelTypesQuery, useGetExtendedFieldTypesQuery, useGetClientVehicleTypesQuery, useGetVehicleDetailsExtendedQuery,
    useFindMatchQuery, useGetDecodedVinQuery, useImportAssetsMutation, useGetImportAssetsTemplateMutation,
    useGetVehicleLocationInfoQuery,
    useGetOnLotStatusesQuery,
    useGetCustomFormOutputQuery,
    useGetCustomFormListQuery,
    useGetVehicleTypeCategoryQuery, useGetDocumentTypesQuery
    // useFlagAssetsMutation
} = vehicleApi;

