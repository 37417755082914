import { Grid, Paper } from "@material-ui/core";
import ActivitiesWidget from "./ActivitiesWidget/ActivitiesWidget";
import AssetListWidget from "./AssetListWidget/AssetListWidget";
import AssetLocationWidget from "./AssetLocationWidget/AssetLocationWidget";
import { fleetManagementDashboardStyle } from "./style";
import TotalAssetsWidget from "./TotalAssetsWidget/TotalAssetsWidget";
import WorkorderApprovalWidget from "./WorkorderApprovalWidget/WorkorderApprovalWidget";
import { useTitle } from "hooks/useTitle";
import { useDispatch, useSelector } from "react-redux";
import { userHasOneClient } from "features/user/userSlice";
import DropdownFilter from "components/DropdownFilter";
import { useGetClientsQuery } from "features/clients/clientSlice";
import { setFilters } from "./fleetManagementDashboardSlice";

const FleetManagementDashboard = () => {
    const classes = fleetManagementDashboardStyle();

    let { data: clients, error: clientsError, isLoading: isLoadingClients } = useGetClientsQuery();
    const hasOneClaim = useSelector(userHasOneClient);
    const { dashboardFilters } = useSelector(state => state.fleetManagementDashboardState);
    const dispatch = useDispatch();

    useTitle('Fleet Management Dashboard');

    const handleFilterChange = (key, value) => {
        const newFiletrs = { ...dashboardFilters, [key]: value };
        dispatch(setFilters(newFiletrs));
    }

    return <Grid container spacing={2}>
        {!hasOneClaim && <Grid item xs={12} className={classes.filterItem}>
            <DropdownFilter
                values={[{ id: '', name: 'All clients' }, ...(clients ?? [])]}
                onSelect={(val) => handleFilterChange('clientId', val)}
                value={dashboardFilters?.['clientId']}
            />
        </Grid>}
        <Grid item xs={12} md={4}>
            <Paper className={classes.widgetWrapper}>
                <WorkorderApprovalWidget />
            </Paper>
        </Grid>
        <Grid item xs={12} md={4}>
            <Paper className={classes.widgetWrapper}>
                <TotalAssetsWidget />
            </Paper>
        </Grid>
        <Grid item xs={12} md={4}>
            <Paper className={classes.widgetWrapper}>
                <AssetListWidget />
            </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
            <Paper className={classes.widgetWrapperBig}>
                <ActivitiesWidget tooltipInfo={`The top 10 Activities performed for your fleet over the selected time frame. 
                        You can toggle to Pricing to see the top 10 activities performed for your fleet by cost.`}/>
            </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
            <Paper className={classes.widgetWrapperBig}>
                <AssetLocationWidget />
            </Paper>
        </Grid>
    </Grid>
}
export default FleetManagementDashboard;