import DatePicker from "components/DatePicker"
import moment from "moment"

export const DateComponent = ({ value, onChange }) => {
    return (
        <DatePicker
            value={value ? moment(value).format("YYYY-MM-DD") : null}
            onSelect={(val) => onChange(val ?? "", "dateValue")}
        />
    )
}