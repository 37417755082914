import { Avatar, Tooltip } from "@material-ui/core"
import HourglassEmptyRoundedIcon from '@material-ui/icons/HourglassEmptyRounded';
import { vehiclePendingSyncCheckInsStyle } from "./style";

export const CustomFormSyncProgress = ({ customFormResponse, style = {}, animate = false }) => {

    const classes = vehiclePendingSyncCheckInsStyle();

    return (<>
        {customFormResponse?.isSyncComplete == false && <Tooltip title={`
            Data is still incoming from the field. If you know which device is the data source, progress can be found on the Pending Syncs screen.
            `} >
            <Avatar variant="square" style={{ backgroundColor: '#ffc761', marginRight: '0.5em', ...style }}>
                <HourglassEmptyRoundedIcon className={animate ? classes.shakeIcon : undefined} style={{ fill: 'white' }} />
            </Avatar>
        </Tooltip>}
    </>
    )
}