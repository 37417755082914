import { Grid, ListItem } from "@material-ui/core";
import WidgetTitle from "../WidgetTitle/WidgetTitle";
import LotsDropdown from "components/SharedDropdowns/LotsDropDown";
import DropdownFilter from "components/DropdownFilter";
import { useGetVehiclesQuery } from "features/vehicles/vehicleSlice";
import { useGetLotsQuery } from "features/lots/lotSlice";
import { useGetStatusesForClientQuery } from "features/vehicles/VehicleStatus/vehicleStatusSlice";
import { useState } from "react";
import DataSearchBar from "components/DataSearchBar";
import FilterService from "services/filterService";
import { assetsListWidgetStyle } from "./style";
import AssetItem from "./AssetItem";
import { AutoSizer } from "react-virtualized";
import { FixedSizeList } from "react-window";
import { arrayToObject } from "utils/object-util";
import { useSelector } from "react-redux";
import { IconCar, IconLot, IconPmfLogo } from "icons";

const AssetListWidget = () => {
    const classes = assetsListWidgetStyle();

    let { data: vehiclesData, error: vehicleError, isLoading: isLoadingVehicles, refetch: refetchVehicles } = useGetVehiclesQuery({ includeHistory: true });
    let { data: lots, error: lotError, isLoading: isLoadingLots, refetch: refetchLots } = useGetLotsQuery();
    let { data: clientVehicleStatuses, error: clientVehicleStatusesError, loading: clientVehicleStatusesLoading } = useGetStatusesForClientQuery({ clientId: 0 })
    vehiclesData = vehiclesData || [];
    lots = lots || [];
    clientVehicleStatuses = clientVehicleStatuses || [];

    const { dashboardFilters } = useSelector(state => state.fleetManagementDashboardState);

    const lotsData = lots.map(item => ({
        ...item,
        pmfLot: item?.pmfLot ? 1 : 2
    }))

    const lotsLookup = arrayToObject(lotsData);

    const [search, setSearch] = useState();
    const [filters, setFilters] = useState({
        'lotId': { value: [] },
        'vehicleStatusId': { value: [] }
    })

    const [pmfLotFilter, setPmfLotFilter] = useState(null);

    const onDropFilter = (filterValue, prop) => {
        setFilters({ ...filters, [prop]: { value: filterValue } });
    };

    const filteredData = FilterService.filter(filters, search, vehiclesData, [])?.filter(
        item => (pmfLotFilter ? lotsLookup?.[item?.lotId]?.pmfLot == pmfLotFilter : true)
            && (!dashboardFilters?.clientId || dashboardFilters?.clientId == item?.clientId)
    );

    const loading = isLoadingVehicles || isLoadingLots || clientVehicleStatusesLoading;

    const Row = ({ index, style }) => {
        const asset = filteredData[index];
        return <ListItem style={style} key={asset.id} className={classes.listItem} >
            <AssetItem asset={asset} />
        </ListItem>
    };

    return <Grid container direction="column" className={classes.graphWrapper}>
        <Grid item >
            <WidgetTitle
                title='Asset Quick Search'
                info={`Here you can search for the individual assets in your fleet and open them to drill into more details.`}
                loading={loading}
                filtersList={{ ...filters, 'pmfLotFilter': { value: pmfLotFilter } }}
                filters={
                    <Grid container spacing={1}>
                        <Grid className={classes.filterIcon} item xs={2}>
                            <IconPmfLogo height={17} fill="#0090fe" />
                        </Grid>
                        <Grid item xs={10}>
                            <DropdownFilter
                                identifier="pmfLot"
                                value={pmfLotFilter}
                                onSelect={(val) => setPmfLotFilter(val)}
                                values={[
                                    { id: 1, name: 'PMF Lots' },
                                    { id: 2, name: 'Non PMF Lots' },
                                ]}
                                showEmpty={true}
                            />
                        </Grid>
                        <Grid className={classes.filterIcon} item xs={2}>
                            <IconLot />
                        </Grid>
                        <Grid item xs={10}>
                            <LotsDropdown
                                title='Lot'
                                identifier='lot'
                                values={lots}
                                onSelect={(val) => onDropFilter(val, 'lotId')}
                                value={filters?.['lotId']?.value}
                                showEmpty={true}
                                multiple={true}
                                pmfLot={pmfLotFilter == 1}
                            />
                        </Grid>
                        <Grid className={classes.filterIcon} item xs={2}>
                            <IconCar width={20} />
                        </Grid>
                        <Grid item xs={10}>
                            <DropdownFilter
                                title="Status"
                                identifier="status"
                                value={filters?.['vehicleStatusId']?.value}
                                onSelect={(val) => onDropFilter(val, 'vehicleStatusId')}
                                values={clientVehicleStatuses ?? []}
                                showEmpty={true}
                                multiple={true}
                            />
                        </Grid>
                    </Grid>
                } />
        </Grid>
        <Grid className={classes.body}>
            <Grid item className={classes.searchBar}>
                <DataSearchBar onSearch={(val) => setSearch(val)} searchVal={search} />
            </Grid>
            <AutoSizer className={classes.list}>
                {({ height, width }) => (
                    <FixedSizeList
                        className="List"
                        height={height + 150}
                        itemCount={filteredData?.length}
                        itemSize={110}
                        width={width}
                    >
                        {Row}
                    </FixedSizeList>
                )}
            </AutoSizer>
        </Grid>
    </Grid>
}
export default AssetListWidget;