import { Grid } from "@material-ui/core";
import { useGetReadinessQuery } from "features/vehicles/readiness/readinessSlice";
import { useGetVehiclesQuery } from "features/vehicles/vehicleSlice";
import { Cell, Legend, Pie, PieChart, ResponsiveContainer, Tooltip } from "recharts";
import { arrayToObject } from "utils/object-util";
import WidgetTitle from "../WidgetTitle/WidgetTitle";
import { totalAssetWidgetStyle } from "./style";
import { IconCar, IconLot } from "icons";
import FilterService from "services/filterService";
import { useGetLotsQuery } from "features/lots/lotSlice";
import { useGetStatusesForClientQuery } from "features/vehicles/VehicleStatus/vehicleStatusSlice";
import { useState } from "react";
import DropdownFilter from "components/DropdownFilter";
import LotsDropdown from "components/SharedDropdowns/LotsDropDown";
import { useSelector } from "react-redux";

const TotalAssetsWidget = () => {
    const classes = totalAssetWidgetStyle();
    const { dashboardFilters } = useSelector(state => state.fleetManagementDashboardState);

    const pendingArrivalStatus = 1;
    let { data: vehiclesData, error: vehicleError, isLoading: isLoadingVehicles, refetch: refetchVehicles } = useGetVehiclesQuery({ includeHistory: true });
    let { data: vehiclesReadiness, error: vehiclesReadinessError, isFetching: isLoadingVehiclesReadiness } = useGetReadinessQuery({});
    let { data: lots, error: lotError, isLoading: isLoadingLots, refetch: refetchLots } = useGetLotsQuery();
    let { data: clientVehicleStatuses, error: clientVehicleStatusesError, loading: clientVehicleStatusesLoading } = useGetStatusesForClientQuery({ clientId: 0 })
    vehiclesData = vehiclesData || [];
    lots = lots || [];
    clientVehicleStatuses = clientVehicleStatuses || [];

    const loading = isLoadingVehicles || isLoadingVehiclesReadiness || isLoadingLots || clientVehicleStatusesLoading;
    const readinessLookup = arrayToObject(vehiclesReadiness, 'vehicleId');

    const [filters, setFilters] = useState({
        'lotId': { value: [] },
        'vehicleStatusId': { value: [] }
    })

    const filteredData = FilterService.filter(filters, null, vehiclesData, [])?.filter(f => (!dashboardFilters?.clientId || dashboardFilters?.clientId == f?.clientId));

    var readyAssets = filteredData?.filter(v => readinessLookup?.[v.id]?.isReady);
    var pendingArrival = filteredData?.filter(v => v.vehicleStatusId == pendingArrivalStatus);
    var allOtherCount = (filteredData?.length ?? 0) - (pendingArrival?.length ?? 0) - (readyAssets?.length ?? 0);

    const graphData = [
        { name: "Ready Assets", value: readyAssets?.length ?? 0, color: '#33A6FE' },
        { name: "Received Assets", value: allOtherCount, color: '#99D3FF' },
        { name: "Pending Arrival", value: pendingArrival?.length ?? 0, color: '#FFD966' },
    ];

    const onDropFilter = (filterValue, prop) => {
        setFilters({ ...filters, [prop]: { value: filterValue } });
    };

    const CustomTooltip = ({ payload }) => {
        return (
            <div className={classes.tooltip}>
                <p>
                    <div><b>{payload?.[0]?.payload.name}</b></div>
                    <div>{payload?.[0]?.payload?.value}</div>
                </p>
            </div>
        );
    };

    const RADIAN = Math.PI / 180;
    let adjustDirection = 1;
    let smallPercentCount = 0;

    const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
        const radius = outerRadius + 35;
        const x = cx + radius * Math.cos(-midAngle * RADIAN);
        const y = cy + radius * Math.sin(-midAngle * RADIAN);
        const value = graphData?.[index]?.value;

        // Adjust label position to avoid overlap
        const labelOffset = 35;
        let adjustedX = x;

        if(index == 0) {
            smallPercentCount = 0;
            adjustDirection = 1;
        }

        if (percent < 0.1 && percent > 0) smallPercentCount++;
        else if (index == 1) adjustDirection = -1;

        if (smallPercentCount == 2) {
            adjustedX = x + (adjustDirection * labelOffset)
        }

        return (
            <>
                {value > 0 && <text x={adjustedX} y={y}
                    fill={graphData?.[index]?.color}
                    textAnchor="middle"
                    dominantBaseline="central"
                    className={classes.label}
                >
                    <tspan x={adjustedX} dy="0em" fontSize="25" fontWeight="600">{`${(percent * 100).toFixed(0)}%`}</tspan>
                    <tspan x={adjustedX} dy="1.8em" fontSize="9" fill="#0061a9" fontWeight="500">{`${value} assets`}</tspan>
                </text>}
            </>
        );
    };

    return <Grid container direction="column" className={classes.graphWrapper}>
        <Grid item>
            <WidgetTitle
                title='Ready Assets'
                info={`This is a breakdown of Ready Assets in your fleet per your Readiness SLA with PMF. 
                        Ready Assets are ready in compliance with your SLA,
                        Received Assets are with PMF, and we are getting them ready for you.
                        Assets Pending Arrival are assets you have informed us of and are in the ParQ system, but have not yet arrived to a PMF Lot.`}
                loading={loading}
                filtersList={filters}
                filters={
                    <Grid container spacing={1}>
                        <Grid className={classes.filterIcon} item xs={2}>
                            <IconLot />
                        </Grid>
                        <Grid item xs={10}>
                            <LotsDropdown
                                title='Lot'
                                identifier='lot'
                                values={lots}
                                onSelect={(val) => onDropFilter(val, 'lotId')}
                                value={filters?.['lotId']?.value}
                                showEmpty={true}
                                multiple={true}
                            />
                        </Grid>
                        <Grid className={classes.filterIcon} item xs={2}>
                            <IconCar width={20} />
                        </Grid>
                        <Grid item xs={10}>
                            <DropdownFilter
                                title="Status"
                                identifier="status"
                                value={filters?.['vehicleStatusId']?.value}
                                onSelect={(val) => onDropFilter(val, 'vehicleStatusId')}
                                values={clientVehicleStatuses ?? []}
                                showEmpty={true}
                                multiple={true}
                            />
                        </Grid>
                    </Grid>
                } />
        </Grid>
        <Grid item style={{ height: 310 }}>
            {!loading && <ResponsiveContainer >
                <PieChart>
                    <Pie
                        data={graphData}
                        dataKey="value"
                        nameKey="name"
                        cx={"50%"} cy={"50%"}
                        innerRadius={50} outerRadius={70}
                        paddingAngle={0}
                        label={renderCustomizedLabel}
                        labelLine={false}
                        startAngle={90} // Starting angle (90° = top of the circle)
                        endAngle={-270}
                    >
                        {graphData.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={entry?.color} />
                        ))}
                    </Pie>
                    <Legend
                        height={36}
                        iconType="circle"
                        layout="horizontal"
                        verticalAlign="bottom"
                        iconSize={10}
                        padding={5}
                        wrapperStyle={{ bottom: 0 }}
                    />
                    <foreignObject x="50%" y="50%" width="40" height="40" transform="translate(-10,-40)">
                        <IconCar width="20" height="20" />
                    </foreignObject>
                    <text
                        x="50%"
                        y="50%"
                        textAnchor="middle"
                        dominantBaseline="middle"
                        fontSize="12"
                        fontWeight="400"
                    >
                        Total Assets
                    </text>
                    <Tooltip content={<CustomTooltip />} />
                </PieChart>
            </ResponsiveContainer>}
        </Grid>
    </Grid>
}
export default TotalAssetsWidget;