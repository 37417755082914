import { makeStyles } from "@material-ui/core";

export const workorderApprovalStyle = makeStyles((theme) => ({
    filterItem: {
        padding: '1.2em 1.5em',
        minWidth: '12vw'
    },
    woBtn: {
        flexBasis: "content"
    },
    notActive: {
        color: 'gray !important'
    },
    active: {
        color: theme.palette.primary.main,
    },
    filterName: {
        fontSize: 12
    },
    count: {
        fontSize: '2em',
        fontWeight: 700,
        textAlign: 'left'
    },
    filterContainer: {
        margin: '0em 1em',
        justifyItems: 'center',
        width: '100%'
    },
    graphWrapper: {
        height: '100%',
        minHeight: '20rem'
    },
    divWrap: {
        padding: '1rem',
    },
    card: {
        marginBottom: '1rem',
        paddingLeft: '1rem',
        height: '100%',
        boxShadow: '2px 3px 10px #d7d6d6',
        position: 'relative',
        justifyContent: 'center',
        minWidth: '350px',
    },
    container: {
        minHeight: "120px",
        maxHeight: "120px",
        alignItems: "stretch !important"
    },
    wrapper: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: '5px'
    },
    textWrapper: {
    },
    lightText: {
        fontSize: 10,
        fontWeight: 350
    },
    regText: {
        fontSize: 10,
        fontWeight: 450
    },
    boldText: {
        fontSize: 12,
        fontWeight: 750
    },
    indicator: {
        height: '0.8em',
        width: '0.8em',
        borderRadius: '50%',
        minWidth: '0.8em',
        cursor: 'pointer',
        marginRight: '0.5em',
    },
    indicatorWrapper: {
        display: 'flex',
        alignItems: 'center'
    },
    actionWrapper: {
        display: 'flex',
        flexWrap: 'wrap',
        alignContent: 'center',
        justifyContent: 'center'
    },
    listIcon: {
        position: 'absolute',
        right: '0.5em',
        top: '0.5em',
    },
    filterIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
}));