
export const SingleValueElement = ({ value }) => {

    let valueObj = value;
    try {
        valueObj = value ? JSON.parse(value ?? "") : ""
    } catch (error) {
        console.log(`SingleValueElement Failed to format value ${value + ""}`)
    }

    return (
        <div>{valueObj?.value ?? ""}</div>
    )
}