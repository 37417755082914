import { makeStyles } from "@material-ui/core";

export const customFormStyle = makeStyles((theme) => ({
    card: {
        margin: '1rem 0 1rem',
        padding: "1rem"
    },
    logo: {
        maxWidth: "60px",
        marginLeft: "1rem",
    },
    loadingCard: {
        margin: '1rem 0 1rem',
        padding: "1rem",
        minHeight: '80vh'
    },
    itemWrapper: {
        display: "flex",
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: '0.5rem'
    },
    itemContainer: {
        margin: "0.5rem",
        backgroundColor: theme.palette.background.default,
        borderRadius: 3,
    },
    title: {
        margin: "0 1rem 0 1rem",
        fontSize: '1.2em'
    },
    outputTitle: {
        marginLeft: "0.5rem",
        fontSize: '1.2em',
        color: '#0061a9',
    },
    sub:{
        margin: "0 0.5rem 0 0.5rem",
        fontSize:24,
        color: '#0061a9',
    },
    buttonsWrapper: {
        //padding: "0.25rem",
        display: "flex",
        justifyContent: "flex-end",
        alignItems: 'center',
        gap: '0.5em'
    },
    backButton: {
        marginTop: '1rem'
    },
    inProgressWarning: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    icon:{
        marginLeft: '0.5rem'
    }
}));