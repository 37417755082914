import { Button, Grid } from "@material-ui/core";
import Authorize from "components/Authorize";
import { permissionProfiles } from "components/Authorize/permissionProfiles";
import DropdownFilter from "components/DropdownFilter";
import LotsDropdown from "components/SharedDropdowns/LotsDropDown";
import ImportActivities from "features/activity/ImportActivities/ImportActivities";
import { useGetClientsQuery } from "features/clients/clientSlice";
import { useGetLotsQuery } from "features/lots/lotSlice";
import { IconAdd } from "icons";
import { orderBy, uniqBy } from "lodash";
import moment from "moment";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CreateWorkOrderPage from './CreateWorkOrder/CreateWorkOrderPage';
import { workorderDashboardPageStyles } from "./WorkorderDashboardPageStyles";
import { setFilters } from "./workorderDashboardSlice";
import { userHasOneClient } from "features/user/userSlice";

export const DashboardFilters = ({ workorders, refetch }) => {
    const classes = workorderDashboardPageStyles();

    const distinctMonths = uniqBy(workorders, 'activityMonth');
    const months = distinctMonths?.map(m => ({ id: m.activityMonth, name: moment(m.activityDate).format('MMM YYYY') }));
    const allMonths = [{ id: 0, name: 'All months' }, ...orderBy(months, 'id')];
    const { filters } = useSelector(state => state.workorderDashboardState);
    const dispatch = useDispatch();
    const hasOneClaim = useSelector(userHasOneClient);
    let { data: lots, error: lotsError, isLoading: isLoadingLots } = useGetLotsQuery();
    let { data: clients, error: clientsError, isLoading: isLoadingClients } = useGetClientsQuery();

    const [openCreate, setOpenCreate] = useState(false);

    const handleFilterChange = (key, value) => {
        const newFiletrs = { ...filters, [key]: value };
        if (key == 'clientId') {
            newFiletrs['lotId'] = [];
        }
        dispatch(setFilters(newFiletrs));
    }

    const isLoading = isLoadingClients || isLoadingLots;

    return (
        <Grid container spacing={1} alignItems="center">
            <Grid item xs={9} className={classes.filtersWrapper}>
                {!hasOneClaim && <Grid item xs={4} className={classes.filterItem}>
                    <DropdownFilter
                        values={[{ id: '', name: 'All clients' }, ...(clients ?? [])]}
                        onSelect={(val) => handleFilterChange('clientId', val)}
                        value={filters?.['clientId']}
                    />
                </Grid>}
                <Grid item xs={4} className={classes.filterItem}>
                    <LotsDropdown
                        clientId={filters?.['clientId']}
                        title='Lot'
                        identifier='lot'
                        values={lots}
                        onSelect={(val) => handleFilterChange('lotId', val)}
                        value={filters?.['lotId']}
                        showEmpty={true}
                        multiple={true}
                    />
                </Grid>
                <Grid item xs={4} className={classes.filterItem}>
                    <DropdownFilter
                        values={allMonths}
                        onSelect={(val) => handleFilterChange('month', val)}
                        value={filters?.['month']}
                    />
                </Grid>
            </Grid>

            <Grid item xs={3} className={classes.buttonsWrapper}>
                <Grid item className={classes.filterItem}>
                    <Authorize profile={permissionProfiles.DASHBOARD.MECHANICAL_DASHBOARD_IMPORT_ACTIVITIES}>
                        <ImportActivities clients={clients} refetch={refetch} />
                    </Authorize>
                </Grid>
                <Authorize profile={permissionProfiles.DASHBOARD.CREATE_WORK_ORDER}>
                    <Grid item>
                        <Button
                            disabled={isLoading}
                            variant="contained"
                            color="primary"
                            onClick={() => { setOpenCreate(true) }}
                            startIcon={<IconAdd />}
                        >
                            Create Work Order
                        </Button>
                        <CreateWorkOrderPage open={openCreate} setOpen={setOpenCreate} />
                    </Grid>
                </Authorize>
            </Grid>
        </Grid >
    )
}