import { createSlice } from '@reduxjs/toolkit';

export const lotsCapacityFiltersSlice = createSlice({
    name: 'lotsCapacityFiltersSlice',
    initialState: {
        filters: {
            lotId: { value: null },
            clientId: { value: null },
            assetClassId: { value: null },
        }
    },
    reducers: {
        setFilters: (state, action) => {
            state.filters = action.payload;
        },
        resetFilters(state) {
            state.filters = {
                lotId: { value: null },
                clientId: { value: null },
                assetClassId: { value: null },
            }
        },
    },
});

export const { setFilters, resetFilters } = lotsCapacityFiltersSlice.actions;

export default lotsCapacityFiltersSlice.reducer;