import { Button, Grid, Paper } from "@material-ui/core";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import BasicModal from "../../components/BasicModal";
import DateRange from "../../components/DateRange";
import DropdownFilter from "../../components/DropdownFilter";
import LotsDropdown from "../../components/SharedDropdowns/LotsDropDown/LotsDropDown";
import { useGetClientsWithConfigsQuery } from "../clients/clientConfigSlice";
import { useGetClientsQuery } from "../clients/clientSlice";
import { useGetLotsQuery } from "../lots/lotSlice";
import { selectIsAccountManager, selectIsAdmin, selectIsClient, selectIsOwner } from "../user/userSlice";
import { dashboardStyles } from "./DashboardStyle";
// import AccessDashboardPage from "./components/AccessDashboard/AccessDashboardPage";
import { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import BillingDashboard from "./components/BillingDashboard";
import DispatchDashboard from "./components/DispatchDashboard";
import NumberOfMovementReport from "./components/DispatchDashboard/NumberOfMovementReport";
import FleetManagementDashboard from "./components/FleetManagementDashboard";
import KpiDashboardPage from "./components/KpiDashboard/KpiDashboardPage";
import WashDashboardPage from "./components/WashDashboard/WashDashboardPage";
import WorkorderDashboardPage from "./components/WorkorderDashboard/WorkorderDashboardPage";
import { setFilters } from "./dashboardSlice";
import { hasPermission } from "components/Authorize/authorizeUtil";
import { permissions } from "components/Authorize/permissions/permissions";

const DashboardPage = () => {
    const classes = dashboardStyles();
    let { data: lots, isLoading: isLoadingLots } = useGetLotsQuery();
    let { data: clients, isLoading: isLoadingClients } = useGetClientsQuery();
    let { data: clientsWithConfigs, error: clientsWithConfigsError, isFetching: isLoadingClientsWithConfigs } = useGetClientsWithConfigsQuery(null);

    clientsWithConfigs = clientsWithConfigs || [];
    lots = lots || [];

    const params = useParams();
    const history = useHistory();

    const isLoading = () => isLoadingLots || isLoadingClientsWithConfigs;
    const isClient = useSelector(selectIsClient);
    const isOwner = useSelector(selectIsOwner);
    const isAdmin = useSelector(selectIsAdmin);
    const isAcctManager = useSelector(selectIsAccountManager);
    const [openSelectFieldsPopup, setOpenSelectFieldsPopup] = useState(false);

    const [clientId, setClientId] = useState();
    const [lotIds, setLotIds] = useState([]);

    const clientHasWashContract = isClient ? clientsWithConfigs?.[0]?.config?.washConfig?.useWashProgram : true;

    const [dashboard, setDashboard] = React.useState(5);

    const dispatch = useDispatch();
    const { filters } = useSelector(state => state.dashboardState);


    const [showClientFilter, setShowClientFilter] = useState(false);
    const [showLotFilter, setShowLotFilter] = useState(false);
    const [showDateFilter, setShowDateFilter] = useState(false);
    const [showMovememntsButton, setShowMovememntsButton] = useState(false);

    const resetFilters = () => {
        setShowClientFilter(false);
        setShowLotFilter(false);
        setShowDateFilter(false);
        setShowMovememntsButton(false);
    }

    useEffect(() => {
        const val = dashboardNameIdMap[params?.type];
        if (dashboards.find(d => d.id == val)) {
            setDashboard(val);
            setLotIds([]);
            dispatch(setFilters({ clientId: '', lotId: Array(0) }));

            resetFilters();

            if (val == 2) {
                setShowClientFilter(true);
                setShowLotFilter(true);
            }

            else if (val == 3) {
                setShowLotFilter(false);
            }

            else if (val == 4) {
                setShowClientFilter(true);
                setShowMovememntsButton(true);
            }

            // else {//Mechanicals is the default
            //     !isClient && setShowClientFilter(true);
            //     setShowLotFilter(true);
            //     setShowDateFilter(true);
            // }
        }
    }, [params])

    const handleChange = (val) => {
        const dashboardKey = dashboards?.find(d => d.id == val);
        history.push(`/dashboard/${dashboardKey?.key}`);
    };

    const handleFilterChange = (key, value) => {
        if (key == 'clientId') {
            setClientId(value);
            setLotIds([]);
            dispatch(setFilters({ ...filters, ['lotId']: [] }));
        }
        if (key == 'lotId')
            setLotIds(value);
        dispatch(setFilters({ ...filters, [key]: value }));
    }

    const onSelectExistingClick = () => {
        setOpenSelectFieldsPopup(true)
    }

    const dashboards = [
        // { id: 1, name: 'Access Dashboard', key: 'access' },
    ];

    if (clientHasWashContract) {
        dashboards.push({ id: 2, name: 'Wash Dashboard', key: 'wash' });
    }

    if (isOwner || (hasPermission(permissions.DASHBOARD.DISPATCH_DASHBOARD_VIEW.key))) {
        dashboards.push({ id: 4, name: 'Dispatch Dashboard', key: 'dispatch' });
    }
    if (isOwner || isAcctManager || (isAdmin && hasPermission(permissions.DASHBOARD.KPI_DASHBOARD_VIEW.key))) {
        dashboards.push({ id: 3, name: 'KPI Dashboard', key: 'kpi' });
    }

    dashboards.push({ id: 5, name: 'Mechanical Dashboard', key: 'mechanical' })
    if (isOwner || hasPermission(permissions.DASHBOARD.FLEET_MANAGEMENT_DASHBOARD_VIEW.key)) {
        dashboards.push({ id: 7, name: 'Fleet Management', key: 'fleetManagement' })
    }

    // if (isOwner) {
    //     dashboards.push({ id: 6, name: 'Billing Dashboard', key: 'billing' });

    // }


    const dashboardMap = {
        // 1: <AccessDashboardPage />,
        2: <WashDashboardPage />,
        3: <KpiDashboardPage />,
        4: <DispatchDashboard />,
        //5: <MechanicalDashboard />,
        5: <WorkorderDashboardPage />,
        6: <BillingDashboard />,
        7: <FleetManagementDashboard />
    }

    const dashboardNameIdMap = {
        // access: 1,
        wash: 2,
        kpi: 3,
        dispatch: 4,
        mechanical: 5,
        billing: 6,
        fleetManagement: 7
    }

    return (
        <>
            {/* {!isLoading() ? */}
            <Paper className={`${classes.card} ${classes.dashboard}`} elevation={1}>

                <Grid container spacing={1} className={classes.filters} alignItems="center">
                    {/* {dashboards?.length > 1 &&
                            <Grid item xs={4}>
                                <DropdownFilter
                                    values={dashboards}
                                    onSelect={handleChange}
                                    value={dashboard}
                                />
                            </Grid>} */}

                    {showClientFilter && <>
                        {clients?.length > 1 &&
                            <Grid item xs>
                                <DropdownFilter
                                    values={[{ id: '', name: 'All clients' }, ...(clients ?? [])]}
                                    onSelect={(val) => handleFilterChange('clientId', val)}
                                    value={filters?.['clientId']}
                                />
                            </Grid>
                        }</>
                    }

                    {showLotFilter &&
                        <Grid item xs>
                            <LotsDropdown
                                clientId={clientId}
                                title='Lot'
                                identifier='lot'
                                values={lots}
                                onSelect={(val) => handleFilterChange('lotId', val)}
                                value={lotIds}
                                showEmpty={true}
                                multiple={true}
                            />
                        </Grid>
                    }

                    {showDateFilter && <>
                        <Grid item xs>
                            <DateRange
                                title='Time Frame'
                                onSelect={(val) => handleFilterChange('date', val)}
                                dateValue={null}
                            />
                        </Grid>
                    </>}

                    {showMovememntsButton && <>
                        <Grid item xs={2}>
                            <Button
                                variant="contained"
                                color="primary"
                                className={classes.button}
                                onClick={onSelectExistingClick}>
                                Number of Movememnts per day per lot Report
                            </Button>
                        </Grid>
                        <BasicModal
                            open={openSelectFieldsPopup}
                            header={`Select Date Range`}
                            handleClose={() => setOpenSelectFieldsPopup(false)}
                            component={<NumberOfMovementReport
                                handleClose={() => setOpenSelectFieldsPopup(false)} />}
                        />
                    </>}

                </Grid>
                {
                    dashboardMap[dashboard]
                }

            </Paper>
            {/* //     : <CircularProgress className={classes.spinner} />
            // } */}
        </>
    );
};

export default DashboardPage;
