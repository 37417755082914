import { createSlice } from "@reduxjs/toolkit";

const getCurrentMonth = () => {
    let date = new Date();
    let year = date?.getFullYear();
    let month = String(date.getMonth() + 1).padStart(2, '0');
    return `${year}${month}`;
}

export const workorderDashboardSlice = createSlice({
    name: 'workorderDashboardSlice',
    initialState: {
        filters: {
            lotId: [],
            clientId: '',
            month: getCurrentMonth()
        }
    },
    reducers: {
        setFilters: (state, action) => {
            state.filters = action.payload;
        },
    },
});

export const { setFilters } = workorderDashboardSlice.actions;
