import { Button, ButtonGroup, Dialog, Grid, Paper } from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useDispatch } from "react-redux";
import Authorize from "../../../../../components/Authorize";
import { permissionProfiles } from "../../../../../components/Authorize/permissionProfiles";
import DataSearchBar from "../../../../../components/DataSearchBar";
import DropdownFilter from "../../../../../components/DropdownFilter";
import mobileBreakpoint from "../../../../../utils/mobileBreakpoint";
import LotAttachmentsDialog from "../../LotAttachmentsDialog";
import LotDialog from "../../LotDialog";
import LotItem from "../LotItem/LotItem";
import ViewToggle from '../ViewToggle';
import { lotsListStyle } from "./LotsListStyle";
import LocationsViewSelector from "../LotsMap/LocationsViewSelector/LocationsViewSelector";
import { isAuthorized } from "../../../../../components/Authorize/authorizeUtil";


const LotsList = ({ allLots, lots, deletePopup, setDeletePopup, updateLotLoading,
    lotViewImages, lotToEdit, subMarketsByLot, searchLot, filterConfig, filters,
    setFilters, searchedVal, onNewLot, screenView, handleViewChange, onEditLot, onViewImages,
    onDelete, onCloseImages, onSaveLot, onCancelEditLot, isNarrowScreen }) => {

    const classes = lotsListStyle();
    const isMobile = useMediaQuery(mobileBreakpoint);
    const dispatch = useDispatch();

    return (
        <>
            <Paper className={`${classes.header} ${isMobile ? classes.headerMobile : ""}`} elevation={1}>

                <Grid container spacing={1} className={classes.headerContainer}>
                    <Grid item xs={isNarrowScreen ? 10 : 3}>
                        <DataSearchBar
                            searchVal={searchedVal}
                            rows={allLots}
                            onSearch={searchLot}
                        />
                    </Grid>

                    <Grid item xs={isNarrowScreen ? 10 : 9} >
                        <Grid container direction='row' className={classes.headerContainer}>
                            <Grid item xs={4}>
                                <LocationsViewSelector
                                    locationFilters={filters}
                                    setLocationFilters={(val) => setFilters(val)}
                                />
                            </Grid>

                            {filterConfig?.map(filter =>
                                <Grid item>
                                    <DropdownFilter
                                        showEmpty={true}
                                        title={filter.title}
                                        values={filter.values}
                                        value={filters?.[filter.key]?.value}
                                        onSelect={(val) => setFilters({ ...filters, [filter.key]: { value: val } })}
                                        multiple={filter.multiple}
                                    />
                                </Grid>
                            )}

                            <Authorize profile={permissionProfiles.LOT.LOT_EDIT_CREATE}>
                                <Grid item xs={1}>
                                    <ButtonGroup fullWidth>
                                        <Button onClick={onNewLot} className={classes.button}>
                                            Create Lot
                                        </Button>
                                    </ButtonGroup>
                                </Grid>
                            </Authorize>

                            <Grid item xs={1}>
                                <ViewToggle
                                    screenView={screenView}
                                    handleViewChange={handleViewChange}
                                />
                            </Grid>
                        </Grid>
                    </Grid>

                </Grid>
            </Paper>

            <Grid container spacing={2} className={classes.listContainer}>
                {lots?.length > 0 && lots?.map((lot, key) => (
                    <Grid item xs={6} key={key} className={isMobile ? classes.lotMobile : classes.listItemContainer}>
                        <Paper className={classes.listItemPaper} >
                            <LotItem lot={lot} onEdit={(lot) => onEditLot(lot)} onViewImages={onViewImages} subMarketsByLot={subMarketsByLot} onDelete={(lot) => onDelete(lot)} />
                        </Paper>
                    </Grid>
                ))}
            </Grid>

            <LotDialog lot={lotToEdit} onSave={onSaveLot} onCancel={onCancelEditLot} saving={updateLotLoading} />
            <LotAttachmentsDialog lot={lotViewImages} onSave={onSaveLot} onClose={() => onCloseImages()} saving={updateLotLoading} />
            <Dialog open={deletePopup} onClose={() => setDeletePopup(false)}>
                <div className={classes.deletePopup}>
                    There are still Assets on this Lot. Please move them all to another Lot and then delete this Lot
                </div>
            </Dialog>
        </>
    )
}

export default LotsList;