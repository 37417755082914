import { useGetAllActivityItemsQuery } from "features/activity/activitySlice";
import { activitiesStyle } from "./style";
import { arrayToObject } from "utils/object-util";
import { Grid } from "@material-ui/core";
import IconMechanicalWrapper from "icons/mechanicals/IconMechanicalWrapper";
import FormatService from "services/formatService";

const ActivitiesCount = ({ activities, count = true, fullList }) => {
    const classes = activitiesStyle();

    let { data: activityItems, error: activityItemsError, isFetching: isLoadingActivityItems } = useGetAllActivityItemsQuery();

    const activityItemsLookup = arrayToObject(activityItems)

    // Get the 10 largest groups
    const largestGroups = Object.entries(activities)
        .map(([key, value]) => ({ key, items: value, count: count ? value.length : value?.reduce((sum, item) => sum + item.revenue, 0), activityItemId: key })) // Convert to array with sizes
        .sort((a, b) => b.count - a.count) // Sort by size in descending order
        .slice(0, fullList ? undefined : 10); // Take the top 10 or return whole list if for the workorder dash

    let highestNumbeOfActivities = largestGroups?.[0]?.count;

    return <Grid className={classes.count} container spacing={1} >
        {largestGroups?.map(item => <Grid item container xs={12} spacing={1} alignItems="center">
            <Grid item >
                <IconMechanicalWrapper activityItem={activityItemsLookup?.[item?.activityItemId]} className={classes.typeIcon} />
            </Grid>
            <Grid item xs={fullList ? 6 : 5} >{activityItemsLookup?.[item?.activityItemId]?.name}</Grid>
            <Grid item xs={5}>
                <div className={classes.activityCountWrapper}>
                    <div className={classes.activityCount} style={{ width: `${item.count > 0 ? item.count / highestNumbeOfActivities * 100 : 0}%` }}>
                    </div>
                    {count ? item.count : FormatService.formatCurrency(item.count?.toFixed(2))}
                </div>
            </Grid>
        </Grid>)}
    </Grid>
}

export default ActivitiesCount;