import { Grid, Paper } from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useDispatch, useSelector } from "react-redux";
import mobileBreakpoint from "../../../../../utils/mobileBreakpoint";
import { LotsCapacityStyle } from "./LotsCapacityStyle";
import DataTable from "../../../../../components/DataTable/DataTable";
import { useGetLotsCapacityQuery, useGetLotsQuery } from "features/lots/lotSlice";
import FilterService, { fieldTypes } from "services/filterService";
import useSortOptions from "hooks/useSortOptions";
import { orderBy } from "lodash";
import ViewToggle from "../ViewToggle";
import DataSearchBar from "components/DataSearchBar";
import { resetFilters, setFilters } from "./LotsCapacityFiltersSlice";
import LoadingSpinner from "components/LoadingSpinner";
import { useState } from "react";
import LotsCapacityTables from "./LotsCapacityTables";
import Authorize from "components/Authorize";
import { permissionProfiles } from "components/Authorize/permissionProfiles";

const LotsCapacity = ({ screenView, handleViewChange,
    searchedVal, searchLot
}) => {

    const classes = LotsCapacityStyle();
    let { data: lots, error: lotError, isLoading: isLoadingLots } = useGetLotsQuery();
    let { data: lotsCapacity, error: lotCapacityError, isLoading: isLoadingLotsCapacity } = useGetLotsCapacityQuery();
    lotsCapacity = orderBy(lotsCapacity ?? [], 'name');
    lots = lots || [];

    const isMobile = useMediaQuery(mobileBreakpoint);
    const dispatch = useDispatch();
    const [sortOptions, sortData] = useSortOptions();
    const { filters } = useSelector(state => state.lotsCapacityFilters); //check
    const [selectedLot, setSelectedLot] = useState(null);

    const updateFilters = (prop, value) => {
        dispatch(setFilters({ ...filters, [prop]: { value } }));
    }

    const onDropFilter = (filterValue, prop) => {
        updateFilters(prop, filterValue);
    };

    const onResetFilters = () => {
        dispatch(resetFilters());
    };

    const columns = [
        { name: "Lot", key: "name" },
        { name: "Assets Physicaly On Lot", key: "assetsPhysicalyOnLot" },
        { name: "Domiciled Assets", key: "domiciledAssets" },
        { name: "Rented Spots (Based On SLA)", key: "rentedSpots" },
        { name: "Used Spots", key: "usedSpots" },
        { name: "Available Spots", key: "availableSpots" },
        { name: "Total Occupied Area", key: "totalOccupiedArea" },
        { name: "Available Area", key: "availableArea" }
    ];

    let filterConfig = [
        { type: fieldTypes.LOT, size: 2, title: 'Lot', key: 'lotId', values: lots, pmfLot: true },
    ];

    let filteredMappings = FilterService.filter(filters, searchedVal, lotsCapacity, filterConfig);

    const onRowClick = (row) => {
        setSelectedLot(row.rowData)
    }

    const mapExportData = v => ({
        Lot: v.name,
        ['Assets Physicaly On Lot']: v.assetsPhysicalyOnLot,
        ['Domiciled Assets']: v.domiciledAssets,
        ['Rented Spots (Based On SLA)']: v.rentedSpots,
        ['Used Spots']: v.usedSpots,
        ['Available Spots']: v.availableSpots,
        ['Total Occupied Area']: v.totalOccupiedArea,
        ['Available Area']: v.availableArea,
    });

    const isLoading = isLoadingLots || isLoadingLotsCapacity;
    return (
        <>
            <Authorize profile={permissionProfiles.LOT.CAPACITY}>
                {!!selectedLot ? <LotsCapacityTables
                    lotCapacity={lotsCapacity.find(lc => lc.lotId === selectedLot.lotId)}
                    setSelectedLot={setSelectedLot}
                    searchedVal={searchedVal}
                    searchLot={searchLot}
                /> :
                    <>
                        <Paper className={`${classes.header} ${isMobile ? classes.headerMobile : ""}`} elevation={1}>
                            <Grid container spacing={1} className={classes.headerContainer}>
                                <Grid item xs={11}>
                                    <DataSearchBar
                                        searchVal={searchedVal}
                                        rows={lotsCapacity}
                                        onSearch={searchLot}
                                    />
                                </Grid>
                                <Grid item xs={1} >
                                    <Grid item xs={1}>
                                        <ViewToggle
                                            screenView={screenView}
                                            handleViewChange={handleViewChange}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Paper>
                        <Grid container spacing={2} className={classes.listContainer}>
                            <LoadingSpinner loading={isLoading} />
                            <DataTable
                                columns={columns}
                                rows={orderBy(
                                    filteredMappings,
                                    sortOptions.columnToSort,
                                    sortOptions.sortDirection
                                )}
                                rowIdentifier='id'
                                onSort={sortData}
                                sortDirection={sortOptions.sortDirection}
                                columnToSort={sortOptions.columnToSort}
                                noItemsMessage='There are no data matching this criteria to display'
                                title={'Lots Capacity'}
                                filters={filters}
                                onDropFilter={onDropFilter}
                                dropDownsConfig={filterConfig}
                                withFilterPanel={true}
                                onResetFilters={onResetFilters}
                                onRowClick={onRowClick}
                                mapExportData={mapExportData}
                            />
                        </Grid>
                    </>}
            </Authorize>
        </>
    )
}

export default LotsCapacity;