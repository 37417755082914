import { Grid, Tooltip } from '@material-ui/core';
import HelpIcon from '@material-ui/icons/Help';
import Authorize from "../../../../components/Authorize";
import { permissionProfiles } from "../../../../components/Authorize/permissionProfiles";
import ExportButton from "../../../../components/ExportButton/ExportButton";
import { IconDownload } from "../../../../icons";
import InactiveUserListDialog from "./inactiveUserListDialog";
import usersPageStyle from './styles';

const InactiveUsers = ({ inactiveUsers, permission, clients, lots, roles, mapExportData, refetchUsers }) => {
    const classes = usersPageStyle();

    const totalUsers = inactiveUsers?.length ?? 0;

    return (
        <Grid className={classes.cardWrapper} container direction="column">
            <div className={classes.actions}>
                <Authorize profile={permissionProfiles.USER_MANAGEMENT.TOTAL_INACTIVE_VIEW_ACCESS_TABLE}>
                    <InactiveUserListDialog
                        permission={permission}
                        clients={clients}
                        lots={lots}
                        roles={roles}
                        user={inactiveUsers}
                        refetchUsers={refetchUsers}
                    />
                </Authorize>

                <Authorize profile={permissionProfiles.USER_MANAGEMENT.TOTAL_INACTIVE_VIEW_ACCESS_EXPORT}>
                    <ExportButton
                        className={classes.exportButton}
                        btnStyle='text'
                        label=""
                        icon={<IconDownload />}
                        fileLabel="Export"
                        data={inactiveUsers}
                        mapExportData={mapExportData}
                    />
                </Authorize>
            </div>

            <Grid item>

                <div>
                    <Grid item className={classes.count} >
                        {totalUsers}
                    </Grid>
                    <div className={classes.text} >
                        Inactive Users
                        <Tooltip title="A user is considered inactive if they have not been seen in ParQ for the selected number of months">
                            <HelpIcon style={{ height: '0.7em' }} />
                        </Tooltip>
                    </div>
                </div>
            </Grid>
        </Grid>
    );
};
export default InactiveUsers;