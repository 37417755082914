import { Button, Grid, Paper } from "@material-ui/core";
import { selectIsClient, selectUserClientId } from "features/user/userSlice";
import JsPDF from 'jspdf';
import { useSelector } from "react-redux";
import { arrayToObject } from "utils/object-util";
import { ImageElement } from "./components/ImageElement";
import { QuestionElement } from "./components/QuestionElement";
import { TextElement } from "./components/TextElement";
import { FormOutputStyle } from "./style";
import { base64image } from "./test";
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import ShimmerLoader from "components/ShimmerLoader/ShimmerLoader";
import { useState, useRef } from "react";
import { formTypes } from "features/Forms/formBuilder/formBuilderConsts";
import FormatService from "services/formatService";
import EXIF from "exif-js";
import { FadeLoader, PulseLoader } from "react-spinners";
import { theme } from "styles/theme";

const elementTypes = {
    Question: 1,
    Title: 2,
    SubTitle: 3,
    Image: 4
}

const FormOutput = ({ headerContent, loading, fileName = 'output.pdf', form, layout, answers, base64Images, vin, formType, formTypeId, clientName }) => {
    const contentRef = useRef();
    const classes = FormOutputStyle();

    const [generating, setGenerating] = useState(false);

    const generatePDF = () => {
        try {

            setGenerating(true);
            const report = new JsPDF('portrait', 'px', 'letter');

            function addText(doc, fontSize, familyFont, typefont, colorTextR, colorTextG, colorTextB, text, x, y) {
                doc.setFontSize(fontSize);
                doc.setFont(familyFont, typefont);
                doc.setTextColor(colorTextR, colorTextG, colorTextB);
                doc.text(text, x, y);
            }
            function drawLine(doc, lineSize, colorLineR, colorLineG, colorLineB, x, y, widthLine) {
                doc.setDrawColor(colorLineR, colorLineG, colorLineB);
                doc.setLineWidth(lineSize);
                doc.line(x, y, widthLine, y);
            }

        function addCoverPage(doc) {
            const imgPath = "/images/carCoverPdf.png";
            const imgWidth = doc.internal.pageSize.width;
            const imgHeight = doc.internal.pageSize.height * (2 / 3) - 12;
            const imgX = 0;
            const imgY = 0;
            doc.addImage(imgPath, 'PNG', imgX, imgY, imgWidth, imgHeight);
            drawLine(doc, 8, 0, 97, 169, 0, imgHeight - 4, doc.internal.pageSize.width)
            const imgPathLogo = "/images/ParqAIlogo.png";
            const imgWidthLogo = 60;
            const imgHeightLogo = 60;
            const imgXLogo = 20;
            const imgYLogo = imgHeight + 15;
            doc.addImage(imgPathLogo, 'PNG', imgXLogo, imgYLogo, imgWidthLogo, imgHeightLogo);
            addText(doc, 18, 'helvetica', 'normal', 0, 97, 169, `${formType} ${formTypeId == formTypes.ConditionReport ? '' : 'Report'}`, 30, imgYLogo + 70);
            addText(doc, 24, 'helvetica', 'bold', 0, 97, 169, vin, 30, imgYLogo + 95);
            addText(doc, 18, 'helvetica', 'bold', 0, 144, 254, clientName, 30, imgYLogo + 120);
            addText(doc, 11, 'helvetica', 'normal', 100, 100, 100, `Generated by Parq.ai on ${FormatService.formatDateTime(new Date())}`, 30, imgYLogo + 140);
            drawLine(doc, 1, 0, 144, 254, 30, imgYLogo + 160, doc.internal.pageSize.width - 30)
            doc.addPage()
        }
        addCoverPage(report);

            function addFooters(doc) {
                const pageCount = doc.internal.getNumberOfPages();
                for (let i = 1; i <= pageCount; i++) {
                    doc.setPage(i);
                    let pageSize = doc.internal.pageSize;
                    let pageHeight = pageSize.height
                        ? pageSize.height
                        : pageSize.getHeight();
                    doc.text(
                        'Page ' + String(i) + ' / ' + String(pageCount),
                        (report.internal.pageSize.getWidth() / 2) - 10,
                        pageHeight - 8,
                    );
                }
            }

        function addGeneratedBy(doc) {
            const pageCount = doc.internal.getNumberOfPages();
            for (let i = 2; i <= pageCount; i++) {
                doc.setPage(i);
                const imgPathLogo = "/images/ParqAIlogo.png";
                const imgWidthLogo = 35;
                const imgHeightLogo = 35;
                const imgXLogo = 25;
                const imgYLogo = 20;
                doc.addImage(imgPathLogo, 'PNG', imgXLogo, imgYLogo, imgWidthLogo, imgHeightLogo);
                addText(doc, 10, 'helvetica', 'normal', 0, 97, 169, `${formType} ${formTypeId == formTypes.ConditionReport ? '' : 'Report'}`, 30, imgYLogo + 35);
                addText(doc, 14, 'helvetica', 'bold', 0, 97, 169, vin, 30, imgYLogo + 50);
                addText(doc, 10, 'helvetica', 'bold', 0, 144, 254, clientName, 30, imgYLogo + 60);
                addText(doc, 8, 'helvetica', 'normal', 100, 100, 100, `Generated by Parq.ai on ${FormatService.formatDateTime(new Date())}`, doc.internal.pageSize.getWidth() - 160, 40);
                drawLine(doc, 1, 0, 144, 254, 30, imgYLogo + 70, doc.internal.pageSize.width - 30)
            }

            }

            report.html(contentRef.current, {
                callback: function (doc) {
                    doc.setFontSize(8);
                    addGeneratedBy(doc);
                    addFooters(doc);
                    report.save(fileName);
                    setGenerating(false);
                },
                html2canvas: {
                    onclone: function (doc, element) {
                        let images = doc.querySelectorAll("img");
                        let targetRect = contentRef.current.getBoundingClientRect();
                        // 1: Normal (0° rotation)
                        // 3: Upside-down (180° rotation)
                        // 6: Rotated 90° counterclockwise (270° clockwise)
                        // 8: Rotated 90° clockwise (270° counterclockwise)
                        images.forEach((img) => {
                            try {
                                let rect = img.getBoundingClientRect();
                                let canvas = document.createElement("canvas");
                                let ctx = canvas.getContext("2d");

                                const width = rect.width;
                                const height = rect.height;


                                canvas.width = width;
                                canvas.height = height;

                                if (img?.src && img?.src != "") {
                                    ctx.scale(1, 1);
                                    ctx.drawImage(img, 0, 0, rect.width, rect.height);

                                    img.parentNode.replaceChild(canvas, img);
                                }
                                //EXIF.getData(img, function () {
                                //const orientation = EXIF.getTag(this, "Orientation");
                                // console.log(orientation);
                                // let deg = 0;
                                // orientation == 3 && (deg = 180);
                                // orientation == 6 && (deg = 270);
                                // orientation == 8 && (deg = 90);
                                // img.style.transform = `rotate(${deg}deg)`
                                //report.addImage(img, "PNG", 200, rect.y - targetRect.y, 100, rect.width, undefined, undefined, deg);
                                //img.remove();

                                // const imgData = img.src; // Get base64 data of the image
                                // const imgWidth = rect.width;
                                // const imgHeight = rect.height;
                                // const posX = 100//rect.x - targetRect.x; // X position relative to the contentRef
                                // const posY = rect.y - targetRect.y; // Y position relative to the contentRef

                                // // Add the image to the PDF
                                // report.addImage(
                                //     imgData,
                                //     "PNG",
                                //     posX,
                                //     posY,
                                //     imgWidth,
                                //     imgHeight,
                                //     undefined,
                                //     "FAST", // Use compression for performance
                                //     deg
                                // );
                                // img.remove()
                                //let deg = 90;
                                // orientation == 3 && (deg = 180);
                                // orientation == 6 && (deg = 270);
                                // orientation == 8 && (deg = 90);

                                // });
                                // img.remove();
                            } catch (err) {
                                console.log("Failed to draw image")
                            }
                        });
                    },

                },
                //margin: [30, 30, 30, 30],
                margin: [95, 30, 30, 30],
                autoPaging: 'text',
                width: 500,
                //y: 535,
                y: 475,
                windowWidth: 1200
            });
        } catch (e) {
            setGenerating(false);
        }
    }

    const allQuestions = form?.sections?.flatMap(section => section.questions);
    const questionLookup = arrayToObject(allQuestions, 'id');
    const answerLookup = arrayToObject(answers, 'questionId');
    const base64ImagesLookup = arrayToObject(base64Images, 'id');

    const getElementComponent = (element) => {
        //console.log(element);
        switch (element.elementType) {
            case elementTypes.Question:
                const q = questionLookup[element.value];
                const a = answerLookup[element.value];
                return <QuestionElement element={element} question={q} answer={a} base64ImagesLookup={base64ImagesLookup} />
            case elementTypes.Image:
                return <ImageElement height={100} value={element?.image} url={element?.pictureUrl} />;
            case elementTypes.Title:
                return <TextElement parse={false} value={element?.value} className={classes.title} style={{ color: '#0090FE', fontSize: '1.2em', fontWeight: 'bold' }} />;
            case elementTypes.SubTitle:
                return <TextElement parse={false} value={element?.value} className={classes.subTitle} style={{ color: '#0061AA', fontSize: '1.2em', fontWeight: 'bold' }} />
            default:
                break;
        }
    }

    return (
        <div className={classes.reportContainer}>
            <div >{headerContent}</div>

            <div ref={contentRef} className={classes.exportableReport} id='report' >

                {loading ?
                    <ShimmerLoader count={5} height="100" /> :
                    <>
                        {/* className={classes.forPDF} <div className={classes.generated}>Generated by Parq.ai on <br /> {FormatService.formatDateTimenew Date())}</div> */}
                        {
                            layout?.sections?.map((section, index) => <>
                                <div style={{
                                    //display: 'flex',
                                    maxWidth: '1000px',
                                    borderBottom: '2px solid #AADAFF',
                                    paddingTop:  '10px',
                                    paddingBottom: '10px'
                                }}>
                                    {section.columns?.map(col => <div
                                    //style={{ flex: 1 }}
                                    //xs={12 / section.columns.length}
                                    >
                                        {col.elements?.map(element => <div style={{ pageBreakInside: 'avoid' }}>
                                            <div className={classes.elementContainer}>
                                                {getElementComponent(element)}
                                            </div>
                                        </div>)}
                                    </div>)}
                                </div>
                                <div style={{ pageBreakAfter: 'always' }}></div>
                            </>)
                        }
                    </>}
            </div >
            {!loading &&
                <Paper className={classes.reportActions} >
                    {<PulseLoader
                        loading={generating}
                        color={theme.palette.primary.main}
                        width={4} />}
                    <Button startIcon={<SaveAltIcon />} variant="outlined" onClick={generatePDF} type="button">
                        {generating ? 'Generating PDF...' : 'Save As PDF'}
                    </Button>
                </Paper>
            }
        </div>
    )
}
export default FormOutput;