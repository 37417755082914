import { Badge, CircularProgress, Grid, IconButton, Tooltip as MuiTooltip, Popover } from "@material-ui/core";
import { widgetTitleStyle } from "./style";
import { IconFilters } from "icons";
import { useState } from "react";
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { TypeViewSelector } from "../../WorkorderDashboard/WorkorderTypesCard/WorkorderTypesCard";

const WidgetTitle = ({ title, info, filtersList = [], filters = null, loading = false, visibleFilters = false, selectedType, setSelectedType }) => {
    const classes = widgetTitleStyle();
    const [anchorEl, setAnchorEl] = useState();
    const [showFilters, setShowFilters] = useState(false)

    const openFilters = (e) => {
        setAnchorEl(e.currentTarget);
        setShowFilters(true);
    }

    const closeFilters = (e) => {
        setAnchorEl();
        setShowFilters(false);
    }

    return <>
        <Grid container className={classes.header}  >
            {loading &&
                <CircularProgress
                    size='1em'
                    style={{ position: 'absolute' }}
                />}
            <Grid className={visibleFilters ? classes.mechDashTitleStyle : classes.widgetTitle} item xs={visibleFilters ? 3 : 10}>
                {title}
            </Grid>
            {filters && (
                <>
                    {!visibleFilters && 
                        <Grid item xs={1}>
                            <Badge
                                className={classes.openFiltersBadge} badgeContent={Object.values(filtersList ?? {})?.filter(f => f?.value !== null && f?.value?.length !== 0)?.length ?? 0} color="error"
                                classes={{ badge: classes.badge }}>
                                <MuiTooltip title={showFilters ? 'Close filters' : 'Show filters'}>
                                    <IconButton size="small" className={classes.filterIcon} onClick={openFilters}> <IconFilters /></IconButton>
                                </MuiTooltip>
                            </Badge>
                        </Grid>
                    }
                    
                    {visibleFilters && <Grid container item xs spacing={1} alignItems="center" justifyContent="flex-end" style={{ paddingRight: '1em'}}>                    
                        <Grid item>{filters}</Grid> 
                        <Grid item><TypeViewSelector selectedType={selectedType} setSelectedType={setSelectedType} /></Grid>
                    </Grid>}

                    {info &&
                        <Grid item xs={1}>
                            <MuiTooltip title={info}>
                                <InfoOutlinedIcon />
                            </MuiTooltip>
                        </Grid>
                    }
                </>
            )}
        <Popover
            open={showFilters}
            onClose={closeFilters}
            anchorEl={anchorEl}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            disableScrollLock
            disablePortal
            keepMounted
        >
            <div className={classes.filters}>{filters}</div>
        </Popover>
        </Grid>
    </>
}
export default WidgetTitle;