import MapIcon from '@material-ui/icons/Map';
import ViewListIcon from '@material-ui/icons/ViewList';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { lotsPageStyle } from "./lotsPageStyle";
import GridOnIcon from '@material-ui/icons/GridOn';
import { ScreenViewTypes } from 'features/lots/LotsEnums';
import { permissionProfiles } from 'components/Authorize/permissionProfiles';
import { hasPermission } from 'components/Authorize/authorizeUtil';
import { selectIsOwner } from 'features/user/userSlice';
import React from 'react';
import { useSelector } from 'react-redux';

const ViewToggle = ({ screenView, handleViewChange }) => {
    const classes = lotsPageStyle();
    const isOwner = useSelector(selectIsOwner);

    const hasCapacityPermission = hasPermission(permissionProfiles.LOT.CAPACITY) || isOwner;

    return (
        <ToggleButtonGroup
            value={screenView}
            exclusive
            onChange={handleViewChange}
            aria-label="screen view"
            className={classes.viewToggle}
        >
            <ToggleButton value={ScreenViewTypes.MAP} aria-label="left aligned">
                <MapIcon />
            </ToggleButton>
            <ToggleButton value={ScreenViewTypes.LIST} aria-label="center aligned">
                <ViewListIcon />
            </ToggleButton>
            {hasCapacityPermission &&
                <ToggleButton value={ScreenViewTypes.CAPACITY} aria-label="right aligned">
                    <GridOnIcon />
                </ToggleButton>
            }
        </ToggleButtonGroup>
    )
}

export default ViewToggle;
