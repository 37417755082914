import { formTypes } from "features/Forms/formBuilder/formBuilderConsts";
import { useGetCustomFormListQuery } from "features/vehicles/vehicleSlice";
import { CustomFormSyncProgress } from "./CustomFormSyncProgress";

export const VehiclePendingSyncCheckIns = ({ vehicleId, style }) => {
    let { data: customFormResponses, error: formError, isLoading: isLoadingForms } = useGetCustomFormListQuery({ vehicleId: vehicleId, formType: formTypes.Checkin });
    const customFormResponse = customFormResponses?.[0];//Sorted descending from the API

    return (<>
        <CustomFormSyncProgress customFormResponse={customFormResponse} style={style} />
    </>
    )
}