import CustomInput from "components/CustomInput"

export const TextComponent = ({ value, onChange, label }) => {

    let valueStr = value ?? "";

    return (
        <CustomInput
            elementType='input'
            value={valueStr}
            onChange={(val) => onChange(val, "freetextValue")}
            label={label}
        />
    )
}