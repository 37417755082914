import { Grid } from "@material-ui/core";
import Toolbar from '@material-ui/core/Toolbar';
import { EditFormOutput } from "features/Forms/FormOutput/EditFormOutput/EditFormOutput";
import FormOutput from "features/Forms/FormOutput/FormOutput";
import { formTypes } from "features/Forms/formBuilder/formBuilderConsts";
import { useGetClientsQuery } from "features/clients/clientSlice";
import { IconCar } from 'icons';
import _ from "lodash";
import { customFormStyle } from "./style";

export const VehicleCustomForm = ({ vehicle, formType, customFormResponse, isLoading, mode = 'download' }) => {
    let { data: clients, error: clientError, isLoading: isLoadingClients } = useGetClientsQuery(null);
    clients = clients || [];
    const clientAccounts = _.flatten(clients?.map(c => c.clientAccounts));
    var classes = customFormStyle();

    return (
        <>
            {mode == 'edit' ? <EditFormOutput customFormResponse={customFormResponse} lotId={vehicle.lotId} formType={formType} />
                : <FormOutput
                    formType={`${formType == formTypes.Checkin ? "Check-In" : formType == formTypes.Checkout ? "Check-out" : "Condition Report"} `}
                    formTypeId={formType}
                    vin={vehicle?.descriptor ?? vehicle?.assetId}
                    clientName={clientAccounts?.find(c => (c.id === customFormResponse?.clientAccountId))?.name}
                    loading={isLoading}
                    fileName={`${formType == formTypes.Checkin ? "Check-In" : formType == formTypes.Checkout ? "Check-out" : "Condition Report"}-${vehicle?.descriptor}.pdf`}
                    headerContent={
                        <Grid style={{ borderBottom: '2px solid #AADAFF' }}>
                            <Toolbar disableGutters>
                                <img
                                    src="/images/ParqAIlogo.png"
                                    className={classes.logo}
                                    alt="parq.ai"
                                />
                            </Toolbar>
                            <Grid container alignItems='center' justifyContent="space-between" >
                                <h1 className={classes.outputTitle}>{`${formType == formTypes.Checkin ? "Check-In" : formType == formTypes.Checkout ? "Check-out" : "Condition Report"} `}</h1>
                            </Grid>
                            <Grid container spacing={1} alignItems='center'>
                                <Grid item className={classes.icon}><IconCar /></Grid>
                                <Grid item><div className={classes.sub}> {vehicle?.descriptor ?? vehicle?.assetId}</div></Grid>
                            </Grid>
                            <h6 className={classes.sub}>{clientAccounts?.find(c => (c.id === customFormResponse?.clientAccountId))?.name}</h6>
                        </Grid>
                    }
                    {...customFormResponse}
                />}
        </>
    )
}