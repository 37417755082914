import { Button, Grid, Paper, Tooltip } from "@material-ui/core";
import ArrowBackIosRoundedIcon from '@material-ui/icons/ArrowBackIosRounded';
import EmptyPlaceholder from 'components/EmptyPlaceholder/EmptyPlaceholder';
import LoadingSpinner from "components/LoadingSpinner";
import { formTypes } from "features/Forms/formBuilder/formBuilderConsts";
import { IconArrowRightCircle, IconCalendarCircle, IconDownload, IconEdit, IconWarning } from "icons";
import { useEffect, useRef, useState } from "react";
import { useHistory, useParams } from 'react-router-dom';
import FormatService from "services/formatService";
import { useGetCustomFormOutputQuery } from "../vehicleSlice";
import CustomFormProgressBar from "./CustomFormProgressBar";
import { customFormResponseStatus, customFormResponseStatusList } from "./CustomFormResponseEnum";
import { VehicleCustomForm } from './VehicleCustomForm';
import { customFormStyle } from "./style";
import { CustomFormSyncProgress } from "./syncProgress/CustomFormSyncProgress";

export const VehicleCustomFormList = ({ vehicle, formType }) => {
    var classes = customFormStyle();
    const history = useHistory();
    let { tab, responseId } = useParams();
    const [selectedResponseId, setSelectedResponseId] = useState(responseId > 0 ? responseId : null);
    const [mode, setMode] = useState(null);

    let { data: customFormResponses, error: formError, isLoading: isLoadingForms } = useGetCustomFormOutputQuery({ vehicleId: vehicle?.id, formType: formType, includeImages: true });
    customFormResponses = customFormResponses || [];

    const firstRender = useRef(true);

    useEffect(() => {
        // Skip at first render
        if (firstRender.current) {
            firstRender.current = false;
            return;
        }
        setMode(null);
        setSelectedResponseId(null);
    }, [formType]);

    const henadleClick = (id, mode) => {
        setMode(mode);
        setSelectedResponseId(id);
        history.push(`${window.location.pathname}/${id}`);
    }

    const goBack = () => {
        setMode(null);
        setSelectedResponseId(null);
        history.push(`/assets/detail/${vehicle?.id}/${tab}`);
    }

    return (
        <>
            {selectedResponseId > 0 && <Button variant="outlined" className={classes.backButton} startIcon={<ArrowBackIosRoundedIcon />} onClick={goBack}>Go Back</Button>}
            <Paper className={isLoadingForms ? classes.loadingCard : classes.card}>
                <Grid container spacing={2}>
                    <LoadingSpinner loading={isLoadingForms} />
                    {selectedResponseId == null && customFormResponses?.length > 0 &&
                        customFormResponses?.map((customFormResponse, index) => (
                            <Grid item xs={12} className={classes.itemContainer}>
                                <Grid item xs={12} className={classes.itemWrapper} key={index}>
                                    <div className={classes.buttonsWrapper}>
                                        <CustomFormSyncProgress customFormResponse={customFormResponse} vehicleId={customFormResponse?.vehicleId} />
                                        <IconArrowRightCircle />
                                        <h2 className={classes.title}>{formType == formTypes.Checkin ? "Check-In" : formType == formTypes.Checkout ? "Check-out" : "Condition Report"}</h2>
                                        <IconCalendarCircle />
                                        <h2 className={classes.title}>{FormatService.formatDateNoConversion(customFormResponse?.answers?.[0]?.createdDate)}</h2>
                                        {customFormResponse?.status === customFormResponseStatus.IN_PROGRESS &&
                                            <Tooltip placement="top" title={customFormResponseStatusList?.find(s => s.id == customFormResponseStatus.IN_PROGRESS)?.name || "Not Completed"}>
                                                <div className={classes.inProgressWarning}><IconWarning size={20} /></div>
                                            </Tooltip>}
                                    </div>
                                    <div className={classes.buttonsWrapper} style={{ gap: "0.5rem" }}>
                                        <Button variant="outlined" startIcon={<IconDownload />} onClick={() => henadleClick(customFormResponse?.id, 'download')}                                >
                                            View & Download Report
                                        </Button>
                                        <Button variant="outlined" startIcon={<IconEdit />} onClick={() => henadleClick(customFormResponse?.id, 'edit')}                                >
                                            Edit
                                        </Button>
                                    </div>
                                </Grid>
                                <CustomFormProgressBar
                                    key={index}
                                    formResponse={customFormResponse}
                                />
                            </Grid>

                        ))
                    }
                    {selectedResponseId == null && customFormResponses?.length == 0 && !isLoadingForms && <EmptyPlaceholder />}
                    {selectedResponseId != null &&
                        <Grid item xs={12}>
                            <VehicleCustomForm vehicle={vehicle} formType={formType} customFormResponse={customFormResponses?.find(res => res.id == selectedResponseId)} mode={mode} isLoading={isLoadingForms} />
                        </Grid>
                    }
                </Grid>
            </Paper >
        </>
    )
}