import { formTypes } from "features/Forms/formBuilder/formBuilderConsts";
import { clientConfigTypes } from "../clientConfigsSetup";

export const StepDefinitionType = [
    { id: 1, name: "Check In" },
    { id: 2, name: "Check Out" }
];

export const StepDefinitionTypeById = {
    CHECKIN: 1,
    CHECKOUT: 2
};

export const ConfigurationTypesById = {
    SLA: 1,
    CUSTOM_FORM: 2,
    PAVE: 3
};

export const getConfigurationTypes = (includePave = false) => [
    { id: ConfigurationTypesById.SLA, name: "SLA" },
    { id: ConfigurationTypesById.CUSTOM_FORM, name: "Custom Form" },
    { id: ConfigurationTypesById.PAVE, name: "PAVE", show: includePave }
].filter(item => item.show !== false);

export const ConfigParamNamesPerType = [
    { id: ConfigurationTypesById.SLA, paramNames: ['useConditionalReport'], hierarchy: 3 },
    { id: ConfigurationTypesById.CUSTOM_FORM, paramNames: ['useCustomCheckin', 'useCustomConditionReport', 'useCustomCheckOut'], hierarchy: 2 },
    { id: ConfigurationTypesById.PAVE, paramNames: ['usePave'], hierarchy: 1 }
]

export const ParamNamesPerClientConfigType = [
    { id: clientConfigTypes.Checkin, paramNames: ['useCustomCheckin', 'usePave'] },
    { id: clientConfigTypes.Checkout, paramNames: ['useCustomCheckOut'] },
    { id: clientConfigTypes.ConditionalReport, paramNames: ['useCustomConditionReport', 'useConditionalReport', 'useSinglePageCheckIn'] } 
];

export const configTypesFormTypesMapping = [
    { 'formType': formTypes.Checkin, 'configType': clientConfigTypes.Checkin},
    { 'formType': formTypes.Checkout, 'configType': clientConfigTypes.Checkout},
    { 'formType': formTypes.ConditionReport, 'configType': clientConfigTypes.ConditionalReport},
]